import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL } = employmentTypes;

const {
  assetValueBetween,
  assetType,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  creditScoreBetween,
} = criteria;

const base = [
  {
    name: "Tier 1",
    rate: 9.24,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreGreaterThan(1050)],
  },
  {
    name: "Tier 2",
    rate: 9.99,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(980, 1049)],
  },
  {
    name: "Tier 3",
    rate: 10.74,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(910, 979)],
  },
  {
    name: "Tier 4",
    rate: 11.49,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(860, 909)],
  },
  {
    name: "Tier 5",
    rate: 12.24,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(810, 859)],
  },
  {
    name: "Tier 6",
    rate: 12.74,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(790, 809)],
  },
  {
    name: "Tier 7",
    rate: 13.24,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(770, 789)],
  },
  {
    name: "Tier 8",
    rate: 13.74,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(755, 769)],
  },
  {
    name: "Tier 9",
    rate: 14.24,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(740, 754)],
  },
  {
    name: "Tier 10",
    rate: 14.74,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(725, 739)],
  },
  {
    name: "Tier 11",
    rate: 15.24,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(715, 724)],
  },
  {
    name: "Tier 12",
    rate: 15.74,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(700, 714)],
  },
  {
    name: "Tier 13",
    rate: 16.24,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(680, 699)],
  },
  {
    name: "Tier 14",
    rate: 16.74,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(655, 679)],
  },
  {
    name: "Tier 15",
    rate: 17.24,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(630, 654)],
  },
  {
    name: "Tier 16",
    rate: 17.74,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(610, 629)],
  },
  {
    name: "Tier 17",
    rate: 19.05,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(595, 609)],
  },
  {
    name: "Tier 18",
    rate: 20.37,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(580, 594)],
  },
  {
    name: "Tier 19",
    rate: 21.68,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(557, 579)],
  },
  {
    name: "Tier 20",
    rate: 22.99,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(530, 556)],
  },
  {
    name: "Tier 21",
    rate: 26.49,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(505, 529)],
  },
  {
    name: "Tier 22",
    rate: 27.24,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(480, 504)],
  },
  {
    name: "Tier 23",
    rate: 27.99,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 200000), creditScoreBetween(475, 479)],
  },
];

const employed = base.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CASUAL, CONTRACT]), timeOfEmploymentGreaterThan(0)],
}));

const motorMisc = employed.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    ageOfAssetAtEndLessThan(20),
    assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike", "ATV/Off-Road Bike", "Caravan"])
  ],
}));

const horseFloats = employed.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    ageOfAssetAtEndLessThan(17),
    assetType(["Horse float"])
  ],
}));

const marine = employed.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    ageOfAssetAtEndLessThan(12),
    assetType(["Trailered boat", "Jetski/PWC"])
  ],
}));

export default {
  date: "27 Feb 2024",
  lender: "Latitude",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/Latitude_Financial_Services_Logo.png",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue"],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      //   "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 395,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(5000, 19999.99)],
    },
    {
      name: "Origination fee",
      value: 1650,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(20000, 49999.99)],
    },
    {
      name: "Origination fee",
      value: 2500,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(50000, 200000)],
    },
    {
      name: "Monthly account fee",
      value: 13,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 4.50,
      max: 4.50,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 2000000)],
    },
  ],
  loading: [],
  info: [
    `Employment: Permanenet Full time/Permanent Part time/Casual: NO minimum time in emplyment, however for new employees <60 days, Employment contract + payslip required.`,
    `Verificatiion of Income: One of Bank Statements (90 days) or Payslip (2 months)`,
    `Contractors/SE - Require an addition to the above - Notice of assessment/individual Tax returns.`,
    `Workers Compensation - Letter from insurer.`
  ],
  productTiers: [...motorMisc, ...marine, ...horseFloats],
};
