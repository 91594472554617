import a from "./base";
import criteria from "../../../utils/criteria";

const { assetValueBetween } = criteria;

const branded = {
  date: "09 Nov 2023",
  lender: "Branded",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/BFS-AUS-updated-logo.png",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue"],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      // "addresses[0].street",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers.[0].entityName",
      "employers.[0].timeOfEmployment",
      "employers.[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 395,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Origination fee",
      value: 1250,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Account fee",
      value: 5.5,
      capitalised: false,
      frequency: "monthly",
    },
    {
      name: "PPSR fee",
      value: 6,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 6,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [],
  info: [],
  productTiers: a,
};
export default branded;
