import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography, TextField, Paper } from "@mui/material";

import { updateAddress, updateEmail, updatePhoneNumberOrg } from "../../store/slices/organisationSlice";
import regex from "../../utils/regex";
import { saveValue, userSelector, getUser } from "../../store/slices/userSlice";

import GoogleAddressSearch from "../Utils/GoogleAddressSearch";

export default function UserProfile() {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  console.log("🚀 ~ file: OrganisationProfile.js:21 ~ UserProfile ~ user:", user);

  const { organisation } = user;

  const [fieldErrors, setFieldErrors] = useState({});

  const [allValues, setAllValue] = useState({
    email: organisation?.email?.address || "",
    phoneNumber: organisation?.phoneNumber?.number || "",
    address: organisation?.address || "",
  });

  const validationRules = {
    email: {
      validate: (value) => regex.email.test(value),
      errorMessage: "Invalid email address",
    },
    phoneNumber: {
      validate: (value) => regex.phoneNumber.test(value),
      errorMessage: "Invalid phone number",
    },
    address: {
      validate: (value) => value.trim() !== "",
      errorMessage: "Model field is required",
    },
  };

  const handle = {
    onChangeField: (e, name) => {
      setAllValue({ ...allValues, [name]: e });
    },
    blurFn: async (fieldName, value) => {
      const { validate } = validationRules[fieldName];
      const isValid = validate(value);
      if (isValid) {
        switch (fieldName) {
          case "email":
            if (organisation?.email?.address !== value) {
              dispatch(saveValue({ emailInUse: false }));
              await dispatch(
                updateEmail({
                  value,
                  organisationId: organisation._id,
                }),
              ).unwrap();
            }
            break;
          case "phoneNumber":
            if (organisation?.phoneNumber?.number !== value) {
              await dispatch(
                updatePhoneNumberOrg({
                  value,
                  organisationId: organisation._id,
                }),
              ).unwrap();
            }
            break;
          case "address":
            if (organisation?.address !== value) {
              dispatch(
                updateAddress({
                  address: value,
                  organisationId: organisation._id,
                  isEmployerAddress: false
                }),
              );
            }
            break;
          default:
            break;
        }
      }
      handle.validateCheck(fieldName, value, isValid);
    },
    validateCheck: (fieldName, value, isValid) => {
      setAllValue((prevValues) => ({
        ...prevValues,
        [fieldName]: value,
      }));
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: isValid ? "" : validationRules[fieldName].errorMessage,
      }));
    },
  };

  const handleUpdateAddress = (newAddressValue) => {
    if (newAddressValue) {
      dispatch(
        updateAddress({
          address: newAddressValue,
          organisationId: organisation._id,
          isEmployerAddress: false
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        style={{ marginTop: "64px" }}
      >
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={10}
          xl={8}
          height="100vh"
          // justifyContent="center"
          alignItems="center"
          direction="column"
          // textAlign="center"
          style={{ padding: "0 100px", marginTop: "64px" }}
        >
          <Paper style={{ padding: "40px" }}>
            <Typography variant="h5" fontWeight="800" style={{ letterSpacing: "-1.2px" }}>
              Organisation profile
            </Typography>

            <Typography variant="body2" style={{ textAlign: "left", margin: "" }}>
              Name
            </Typography>
            <Typography variant="body2" style={{ textAlign: "left", margin: "0 0 20px" }}>
              {organisation?.name}
            </Typography>
            <Typography variant="body2" style={{ textAlign: "left", margin: "" }}>
              ABN
            </Typography>
            <Typography variant="body2" style={{ textAlign: "left", margin: "0 0 20px" }}>
              {organisation?.abn}
            </Typography>

            <TextField
              style={{ margin: "0 0 20px" }}
              fullWidth
              type="email"
              name="email"
              label="Email"
              margin="dense"
              value={allValues?.email}
              error={fieldErrors?.email}
              helperText={fieldErrors?.email}
              onChange={(event) => handle.onChangeField(event?.target?.value, "email")}
              onBlur={(e) => handle.blurFn("email", e.target.value)}
              inputProps={{ maxLength: 50 }}
            />

            <TextField
              style={{ margin: "0 0 20px" }}
              fullWidth
              type="tel"
              name="phoneNumber"
              label="Phone number"
              autoComplete="tel"
              value={allValues?.phoneNumber}
              error={fieldErrors?.phoneNumber}
              helperText={fieldErrors?.phoneNumber}
              onChange={(event) => handle.onChangeField(event?.target?.value, "phoneNumber")}
              onBlur={(e) => handle.blurFn("phoneNumber", e.target.value)}
              margin="dense"
              inputProps={{ maxLength: 15 }}
            />

            <GoogleAddressSearch size="small" handleCreateAddress={handleUpdateAddress} address={allValues?.address} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
