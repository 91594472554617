import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateField } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";
import { handleMixpanelQuoteEvent } from "../../..//utils/mixPanelTrackEvents";
import {
  customersSelector,
  saveCustomer,
  updateCustomer,
} from "../../../store/slices/applicationFormSlice";

const QuoteDateOfBirth = () => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const customers = useSelector(customersSelector);
  const [dateOfBirth, setDateOfBirth] = useState(null)
  // const [dateOfBirth, setDateOfBirth] =
  //   useState(customers?.[0]?.dateOfBirth && customers?.[0]?.dateOfBirth !== undefined && setDateOfBirth(dayjs(customers?.[0]?.dateOfBirth, "DD-MM-YYYY")));

  function updateDateOfBirth(value) {
    handleMixpanelQuoteEvent(applicationId, "date of birth", moment(value)?.format("YYYY-MM-DD"))

    dispatch(saveCustomer({
      customerId: customers[0]?._id,
      dateOfBirth: moment(value)?.format("DD-MM-YYYY")
    }))

    dispatch(updateCustomer({
      customerId: customers[0]?._id,
      data: {
        dateOfBirth: moment(value)?.format("DD-MM-YYYY")
      }
    }))
  }

  useEffect(() => {
    if (customers?.[0]?.dateOfBirth) {
      setDateOfBirth(dayjs(customers?.[0]?.dateOfBirth, "DD-MM-YYYY"))
    }
  }, [customers?.[0]?.dateOfBirth]);

  return (
    <>
      <DateField
        label="Date of birth"
        size="small"
        format="DD-MM-YYYY"
        variant="filled"
        value={dateOfBirth || null}
        style={{ background: "#ffffff" }}
        onChange={(event) => {
          setDateOfBirth(event?.$d || null)
        }}
        onBlur={() => updateDateOfBirth(dateOfBirth)}
      />
    </>
  )
}

export default QuoteDateOfBirth 