import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { Grid, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import Document from "./Document";
import { ALLOWED_DOCUMENT_TYPE, ERRORS_MESSAGE, MAX_DOCUMENT_UPLOAD_SIZE, SUCCESS_MESSAGE, usedDocsNames, VALID_DOCUMENT_TYPE_EXTENSION } from "../../constants";
import regex from "../../utils/regex";
import { getDocuments, uploadDocument } from "../../store/slices/applicationFormSlice";

const CustomerOtherDocuments = React.memo(({ customer, applicationId }) => {
  const Input = styled("input")({
    display: "none",
  });
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef();
  const documentUploadLoading = "";


  const [documentName, setDocumentName] = useState("");
  const [error, setError] = useState("");
  const [buttonColorText, setButtonColorText] = useState({
    buttonBackground: "secondary",
    buttonText: "Upload Document",
  });

  const [fieldErrors, setFieldErrors] = useState({
    documentName: "",
  });

  const validationSchema = Yup.object({
    documentName: Yup.string()
      .optional()
      .matches(regex.alphaNumericAndSpecificChar, "Only letters, numbers and . ! / ( ) - + characters allowed.")
      .min(3, "Minimum of 3 characters")
      .max(50, "Maximum of 50 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    handleUploadDocument: (event, label, customerId) => {
      const files = event.target.files;
      const newSelectedFiles = [];
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (ALLOWED_DOCUMENT_TYPE.includes(file.type)) {
          if (file.size <= MAX_DOCUMENT_UPLOAD_SIZE) {
            newSelectedFiles.push(file);
          } else {
            setError(ERRORS_MESSAGE.documentSizeMsg);
          }
        } else {
          setError(ERRORS_MESSAGE.documentTypeMsg);
        }
      }
      if (newSelectedFiles?.length) {
        newSelectedFiles.forEach((file) => {
          formData.append("file", file);
        });
        formData.append("applicationId", applicationId);
        formData.append("customerId", customerId);
        formData.append("name", label);
        dispatch(uploadDocument(formData)).then((res) => {
          if (res?.payload?.data) {
            enqueueSnackbar(SUCCESS_MESSAGE.fileUploadSuccess, {
              variant: "success",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
          // Update button style
          if (res) handle.documentButtonSuccessStyle();
        });
        event.target.input = null;
        setError("");
        setDocumentName("");
      }
    },
    documentButtonSuccessStyle: () => {
      setButtonColorText({
        buttonBackground: "success",
        buttonText: "Successfully uploaded",
      });

      setTimeout(() => {
        setButtonColorText({
          buttonBackground: "secondary",
          buttonText: "Upload Document",
        });
        dispatch(getDocuments(customer._id));
      }, 6000);
    },
    onChangeField: async (value, name) => {
      let isValid = true;
      if (name === "documentName") {
        isValid = regex.alphaNumericAndSpecificChar.test(value) && value?.length <= 50;
      }

      if (!isValid) {
        await validateField(name, value);
        return;
      }
      setDocumentName(value);
      await validateField(name, value);
    },
  };

  return (
    <React.Fragment>
      <Grid item xs={12} style={{ marginBottom: "30px" }}>
        <Typography fontWeight={600} style={{ marginBottom: "15px", color: "#1d4164" }}>
          Other documents
        </Typography>
        <Grid item xs={12} sm={12}>
          <Stack spacing={1} direction="row">
            <TextField
              id="outlined-basic"
              type="text"
              name="documentName"
              label="Document name"
              variant="outlined"
              size="small"
              value={documentName || ""}
              error={fieldErrors?.documentName}
              helperText={fieldErrors?.documentName}
              onChange={(event) => handle.onChangeField(event?.target?.value, "documentName")}
            />

            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              style={{
                marginTop: fieldErrors["documentName"] === "" ? "0px" : "-24px",
              }}
            >
              <label htmlFor={`upload-document-${customer._id}`}>
                <Input
                  accept={VALID_DOCUMENT_TYPE_EXTENSION}
                  id={`upload-document-${customer._id}`}
                  type="file"
                  value={null}
                  disabled={!documentName ? true : fieldErrors?.documentName}
                  onChange={(event) => handle.handleUploadDocument(event, documentName, customer._id)}
                />
                <LoadingButton
                  loading={documentUploadLoading}
                  variant="contained"
                  component="span"
                  color={buttonColorText?.buttonBackground}
                  disabled={!documentName ? true : fieldErrors?.documentName}
                >
                  {buttonColorText?.buttonText}
                </LoadingButton>
              </label>
            </Stack>
          </Stack>
          {error && (
            <Typography variant="body1" style={{ color: "red" }}>
              {error}
            </Typography>
          )}
          {/* {!documentName && (
            <Typography variant="caption">
              Enter a document name to upload a document
            </Typography>
          )} */}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          padding: "0 0 20px",
          borderBottom: "1px solid rgba(0,0,0,0.12)",
        }}
      >
        {customer?.documents?.length > 0 && (
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <Grid container item xs={12} spacing={2}>
                {customer?.documents?.length > 0 ? (
                  customer?.documents
                    ?.filter(
                      (doc) =>
                        !usedDocsNames.includes(doc.name),
                    )
                    ?.map((doc) => (
                      <Grid key={nanoid()} ref={ref} item>
                        <Document document={doc} name="otherDocuments" />
                      </Grid>
                    ))
                ) : (
                  <>
                    <Grid item xs={3}>
                      <input id="contained-button-file" type="file" style={{ display: "none" }} />
                      <label htmlFor="contained-button-file">
                        <Skeleton animation={false} variant="rectangular" height={100} />
                      </label>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
});

export default CustomerOtherDocuments;
