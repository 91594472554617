import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { applicationSelector, userDetailsSelector } from "../../store/slices/applicationFormSlice";

import { userSelector, saveValue } from "../../store/slices/userSlice";
import { createOrganisation, organisationSelector } from "../../store/slices/organisationSlice";

export default function Organisation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [organisationName, setOrganisationName] = useState("");
  const { isNewApplication } = useSelector(userDetailsSelector);
  const { _id } = useSelector(applicationSelector);
  // const [isCreating, setIsCreating] = useState(false);

  const organisation = useSelector(organisationSelector);
  const user = useSelector(userSelector);
  const { isCreating, requestSuccess } = organisation;

  async function handleCreateOrganisation() {
    // dispatch(saveValue({ isCreating: true }));
    await dispatch(
      createOrganisation({
        name: organisationName,
        status: "active",
      }),
    ).unwrap();
  }

  useEffect(() => {
    console.log("organisation", organisation);
    if (organisation?._id) {
      navigate("/applications");
      // dispatch(startApplication("consumer"))
    }
  }, [organisation]);

  useEffect(() => {
    // console.log("isNewApplication", isNewApplication);
    // console.log("_id", _id);
    if (isNewApplication && _id) {
      dispatch(saveValue({ isNewApplication: false }));

      navigate(`/quote/${_id}`);
      // window.location.reload()
    }
  }, [isNewApplication]);

  useEffect(() => {}, []);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="start"
        alignItems="start"
        direction="row-reverse"
      >
        <Grid
          container
          item
          xs={12}
          sm={8}
          md={6}
          lg={6}
          height="100vh"
          // justifyContent="center"
          alignItems="start"
          direction="column"
          textAlign="left"
          style={{ padding: "10vh 0 10vh 10vw" }}
        >
          {!requestSuccess && (
            <>
              <Typography
                variant="h4"
                fontWeight="800"
                style={{
                  letterSpacing: "-1.8px",
                  margin: "0",
                }}
              >
                Last thing {user.firstName}!
              </Typography>

              <Typography
                variant="h4"
                style={{
                  letterSpacing: "-1px",
                  margin: "0 0 20px",
                }}
              >
                What's your business/organisation name?
              </Typography>

              <TextField
                fullWidth
                variant="outlined"
                label="Business/Organisation name"
                value={organisationName}
                placeholder="Type business/orginsation name"
                style={{ margin: "0 0 20px 0", background: "#ffffff" }}
                onChange={(event) => setOrganisationName(event.target.value)}
              />

              <LoadingButton
                color="secondary"
                // endIcon={<SendOutlinedIcon />}
                loading={isCreating}
                // loadingIndicator="Generating..."
                loadingPosition="end"
                variant="contained"
                disabled={!organisationName}
                onClick={() => handleCreateOrganisation()}
              >
                Finish
              </LoadingButton>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
