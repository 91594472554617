import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { Button, Box, Typography } from "@mui/material";
import { applicationSelector, saveCustomerDetails } from "../store/slices/applicationFormSlice";
import CustomerLoginModel from "../components/Models/CustomerLoginModel";
import CustomerDetails from "../components/CustomerDetails";
import { customerSelector, getCustomerById } from "../store/slices/customerSlice";

// It's consumer contact details page where use can update there record
export default function ContactDetails() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const application = useSelector(applicationSelector);
  const { isCustomerLogin } = useSelector(customerSelector);

  const contactData = application?.customers?.[0];

  const [contactFieldErrors, setContactFieldErrors] = useState({
    customerTitle: "",
    firstName: "",
    lastName: "",
    IDNumber: "",
    dateOfBirth: "",
    IDCardNumber: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    yearsAt: "",
    monthsAt: "",
    IDExpiryDate: "",
  });
  // const [isValidCred, setIsValidCred] = useState(false);
  const [openCustomerLoginModel, setOpenCustomerLoginModel] = useState(true);

  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const customerId = params.cid;
  const applicationId = params.aid;
  const applicationType = params.type;

  useEffect(() => {
    // if (!customerId || !applicationId) navigate("/404")
    if (customerId && !customerId) {
      dispatch(getCustomerById({ customerId: customerId }))
    }
  }, [customerId]);

  // use contact data set to customer model

  const handle = {
    handleCloseCustomerLoginModel: (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenCustomerLoginModel(false)
      }
    },
    submitCustomerDetails: () => {
      if (customerId && applicationId && applicationType) {
        dispatch(saveCustomerDetails({ customerId, applicationId, applicationType }))
      }
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Grid container item sm={12} md={8} lg={8} xs={8} >

        {/* Customer Details */}
        {isCustomerLogin && contactData && customerId && applicationId && applicationType && !contactData?.isCustomerDetailsSaved &&
          <Box>
            <CustomerDetails
              key={customerId}
              customer={contactData}
              fieldErrors={contactFieldErrors}
              setFieldErrors={setContactFieldErrors}
              applicationType={applicationType}
              applicationId={applicationId}
              index={0}
            />

            {/* Make button sticky on bottom */}
            <Box
              width={'100%'}
              display='flex'
              justifyContent='flex-end'
              pb={3}
              pr={3}
              pt={1}
              position={'fixed'}
              bottom={0}
              left={0}
              zIndex={98}
              bgcolor={'#FFFFFF'}
            >
              <Button
                onClick={handle.submitCustomerDetails}
                variant="contained"
                style={{
                  width: '130px',
                  zIndex: 99
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        }

        {contactData?.isCustomerDetailsSaved && (
          <Box display='flex' alignItems='center' flexDirection='column' width={'100%'}>
            <Typography variant="h4" component="h1" gutterBottom>
              Thank you completing the application form
            </Typography>
            <Typography variant="body1">
              Your data has been successfully submitted.
            </Typography>
          </Box>
        )}
      </Grid>

      <CustomerLoginModel
        customerId={customerId}
        applicationId={applicationId}
        openCustomerLoginModel={openCustomerLoginModel}
        handleCloseCustomerLoginModel={handle.handleCloseCustomerLoginModel}
      />
    </div>
  );
}
