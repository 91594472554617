import { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
// import { handleMixpanelQuoteEvent } from "../../../utils/mixPanelTrackEvents";
// import { saveValue, userSelector } from "../../../store/slices/userSlice";
// import mixpanel from "../../../services/mixpanel";
import {
  customersSelector,
  // saveCustomer,
  updateCreditProfile,
  userDetailsSelector,
} from "../../../store/slices/applicationFormSlice";

const QuoteCreditScore = () => {
  const dispatch = useDispatch();
  // const { applicationId } = useParams();
  const customers = useSelector(customersSelector);
  // const user = useSelector(userSelector)
  // const { walkthroughIndex } = user
  const { isNewApplication } = useSelector(userDetailsSelector);
  const [scoreValue, setScoreValue] = useState(customers?.[0]?.creditProfile?.score);

  function handleScore(event) {
    setScoreValue(event.target.value)
    // console.log(walkthroughIndex, event.target.value)
    // if (user?.status === "new" && walkthroughIndex < 7 && event.target.value * 1 === 650) {
    //   // mixpanel.track(`Demo: Step 8 - Employment type`)
    //   dispatch(saveValue({ walkthroughIndex: 7 }))
    //   event.target.blur()
    // }
  }

  function updateCreditScore(value) {
    // handleMixpanelQuoteEvent(applicationId, "credit score", value)
    console.log({ value })

    // dispatch(saveCustomer({
    //   customerId: customers[0]?._id,
    //   creditProfile: {
    //     score: value
    //   }
    // }))

    dispatch(
      updateCreditProfile({
        score: value,
        customer: customers[0]?._id,
      })
    );
  }

  useEffect(() => {
    if (customers?.[0]?.creditProfile?.score) setScoreValue(customers?.[0]?.creditProfile?.score)
  }, [customers?.[0]?.creditProfile?.score]);

  useEffect(() => {
    if (isNewApplication) setScoreValue("")
  }, [isNewApplication])

  return (
    <>
      <TextField
        className="eight-step"
        fullWidth
        style={{ background: "#ffffff" }}
        variant="filled"
        defaultValue=""
        type="number"
        name="creditscore"
        label="Score"
        size="small"
        placeholder="650"
        value={scoreValue || ""}
        onChange={handleScore}
        onBlur={async (event) => {
          if (event.target.value !== customers?.[0]?.creditProfile?.score?.toString()) {
            updateCreditScore(event.target.value);
          }
        }}
      />
    </>
  )
}

export default QuoteCreditScore