import { useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { requestResetPassword } from "../../store/slices/userSlice";
import regex from "../../utils/regex";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handle = {
    onChangeFn: (value) => {
      setEmail(value);
      if (regex.email.test(value.toLowerCase())) setEmailError(false);
      else setEmailError(true);
    },
    handleRequestResetPassword: async () => {
      if (regex.email.test(email.toLowerCase())) await dispatch(requestResetPassword(email)).unwrap();
    },
  };

  return (
    <>
      <Grid
        container
        item
        height="calc(100vh - 64px)"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        marginTop="-64px"
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          // height="100vh"
          justifyContent="center"
          alignItems="center"
          direction="column"
          // textAlign="left"
          style={{ padding: "100px" }}
          // spacing={1}
        >
          <Typography
            variant="h4"
            style={{
              letterSpacing: "-1.8px",
              fontWeight: 800,
              marginBottom: "10px",
            }}
          >
            Forgot password
          </Typography>
          {/* <br /> */}
          <Typography
            // variant="body1"
            fontWeight="400"
            style={{ marginBottom: "10px" }}
          >
            What is the email address for your account?
          </Typography>

          <TextField
            fullWidth
            // id="outlined-basic"
            type="email"
            name="email"
            label="Email"
            // variant="filled"
            // variant="outlined"
            // size="small"
            value={email}
            onChange={(event) => handle.onChangeFn(event.target.value)}
            margin="dense"
            error={emailError}
            helperText={emailError ? "Invalid email." : null}
            // onBlur={() => {}}
          />

          <LoadingButton
            fullWidth
            // endIcon={<SendOutlinedIcon />}
            loading={""}
            // loadingIndicator="Generating..."
            loadingPosition="end"
            variant="contained"
            disabled={""}
            onClick={() => handle.handleRequestResetPassword()}
            style={{ marginTop: "20px" }}
          >
            Request reset password
          </LoadingButton>

          {/* <Snackbar
          open={submitSuccess}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSubmitSuccess(false)}
          // message="Application submitted"
          // action={action}
        >
          <Alert
            onClose={() => setSubmitSuccess(false)}
            elevation={6}
            severity="success"
            sx={{ width: "100%" }}
          >
            Error
          </Alert>
        </Snackbar> */}
        </Grid>
      </Grid>
    </>
  );
}
