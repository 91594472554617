import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

// import House from "@mui/icons-material/HouseTwoTone";
// import Hotel from "@mui/icons-material/HotelTwoTone";
// import HomeWork from "@mui/icons-material/HomeWorkTwoTone";
import { v4 as uuidv4 } from "uuid";
import colours from "../../style/color";

// const icons = {
//   House,
//   Hotel,
//   HomeWork,
// };

export default function RadioButtonGroup(props) {
  const [value, setValue] = useState(props);
  const toggleButtonStyle = {
    width: "100%",
    padding: "10px",
    minHeight: "48px",
    // background: "rgba(0, 0, 0, 0.06)"
  };

  // const buttonContainerStyle = {
  //   // background: colours.grey,
  //   boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
  //   "&:hover": {
  //     background: colours.darkGrey,
  //   },
  // };

  const toggleButtonContentStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "60px",
    // padding: "16px 0",
    color: colours.black,
    fontWeight: 400,
    lineHeight: 1.2,
  };

  useEffect(() => {
    // console.log(props.value);
    if (props.value) setValue(props.value);
  }, []);

  return (
    <>
      <Grid container spacing={1} xs={12} pt={"15px"}>
        <ToggleButtonGroup
          fullWidth
          exclusive
          name={props?.name}
          value={value}
          style={props?.style ? props?.style : { margin: "0 0 0 8px" }}
          onChange={(event, newValue) => {
            // console.log(newValue);
            // if (newValue !== value) {
            setValue(event.target.innerText);
            return props.handleValue(event.target.innerText);
            // }
          }}
        >
          {props?.options?.map((option) => (
            // console.log(option?.label);

            // const Icon = icons[option.icon];
            // console.log(Icon);
            // <Grid key={nanoid()} item xs={4}>
            // {/* <Paper key={nanoid()} style={buttonContainerStyle}> */}
            <ToggleButton
              key={uuidv4()}
              selected={props?.value === option.label}
              // value={value}
              style={props?.optionStyle ? props?.optionStyle : toggleButtonStyle}
            >
              <div key={uuidv4()} style={toggleButtonContentStyle}>
                {/* {option.icon && <Icon key={nanoid()} />} */}
                <p
                  key={uuidv4()}
                  style={{
                    textTransform: "none",
                    letterSpacing: 0,
                    // margin: "5px 0 0",
                  }}
                >
                  {option.label}
                </p>
              </div>
            </ToggleButton>
            // {/* </Paper> */}
            // </Grid>
          ))}
        </ToggleButtonGroup>
      </Grid>
    </>
  );
}
