import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { servicesName } from "../../../components/Utils/helperFunction";
import { ERRORS_MESSAGE, STATUS_CODE, cellAlign } from "../../../constants";
import { getAllRoleDetail, getRoleById, saveRolePermissions, userSelector } from "../../../store/slices/userSlice";
import { TableLoader } from "../../../components/customComponents/TableLoader";


const RolePermission = () => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { userRoleList, userRoleLoader, getRoleByIdLoader, roleById } = useSelector(userSelector);

  const [applicationAccess, setApplicationAccess] = useState(false);
  const [userSettingsAccess, setUserSettingsAccess] = useState(false);
  const [servicesAccess, setServicesAccess] = useState({
    All: false,
    bank_statements: false,
    asic_extract: false,
    asic_extract_ppsr: false,
    id_verification: false,
    blug_flag: false,
    equifax_basic_report: false,
    equifax_commercial_report: false,
    equifax_with_onescore_report: false,
  });
  const all = "All";

  useEffect(() => {
    dispatch(getAllRoleDetail());
  }, []);

  const handle = {
    applicationAccess: (event) => {
      const { value } = event.target;
      setApplicationAccess(value);
    },
    servicesAccess: (event) => {
      const { checked, name } = event.target;
      if (name === all) {
        const allCheckedService = Object.fromEntries(
          Object.entries(servicesAccess).map(([key, value]) => [key, checked]),
        );
        setServicesAccess(allCheckedService);
      } else {
        const updatedServicesAccess = { ...servicesAccess, [name]: checked };
        const { All, ...UpdateServices } = { ...updatedServicesAccess };
        const isAllChecked = Object.values(UpdateServices).every((value) => value);
        updatedServicesAccess[all] = isAllChecked;
        setServicesAccess(updatedServicesAccess);
      }
    },
    userSettingsAccess: (event) => {
      const { checked } = event.target;
      setUserSettingsAccess(checked);
    },
    editPermissions: (userType, roleId) => {
      dispatch(getRoleById({ _id: roleId })).then(({ payload }) => {
        const updatedServicesAccess = { ...servicesAccess, ...payload?.data?.services };
        const isAllChecked = Object.values(payload?.data?.services).every((value) => value);
        updatedServicesAccess[all] = isAllChecked;

        setApplicationAccess(payload?.data?.application?.access || false);
        setUserSettingsAccess(payload?.data?.billing?.all || false);
        setServicesAccess(updatedServicesAccess);
      });
      setExpand({
        [userType]: !expand[userType],
      });
    },
    savePermissions: (userType, organisationId, roleId) => {
      const { All, ...UpdateServicesAccess } = { ...servicesAccess };
      const data = {
        data: {
          application: { access: applicationAccess },
          billing: { all: userSettingsAccess },
          services: UpdateServicesAccess,
          userType: userType,
          organisation: organisationId,
        },
        id: roleId,
      };
      dispatch(saveRolePermissions(data)).then(({ payload }) => {
        if (payload?.status_code === STATUS_CODE.success) {
          setExpand({
            [userType]: !expand[userType],
          });
          enqueueSnackbar(payload?.status_message, {
            variant: "success",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
  };

  return (
    <Grid container spacing={1.5} direction={"column"} width={"100%"}>
      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {userRoleLoader &&  <TableLoader columnSpan={5} />}
              {!userRoleList || (userRoleList?.length === 0 && !userRoleLoader) ? (
                <TableRow>
                  <TableCell align={cellAlign} colSpan={5}>
                    No Record Found
                  </TableCell>
                </TableRow>
              ) : (
                userRoleList.map((role) => (
                  <>
                    <TableRow
                      key={role?._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" align="left" colSpan={4} sx={{ fontSize: "1rem" }}>
                        {role?.userType}
                      </TableCell>

                      <TableCell align={"right"}>
                        {expand[role?.userType] ? (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handle.savePermissions(role?.userType, role?.organisation, role?._id)}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handle.editPermissions(role?.userType, role?._id)}
                          >
                            Edit
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                    {expand[role?.userType] && getRoleByIdLoader &&  <TableLoader columnSpan={5} />}
                    {expand[role?.userType] && roleById?.length === 0 && !getRoleByIdLoader ? (
                      <TableRow>
                        <TableCell align={cellAlign} colSpan={5}>
                          No Record Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      expand[role?.userType] && (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Stack justifyContent={"space-between"} direction="row">
                              <Stack>
                                <FormControl sx={{ m: 3 }} required>
                                  <FormLabel sx={{ mb: 1 }}>Application Access</FormLabel>
                                  <Stack
                                    direction={"row"}
                                    columnGap={1}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  >
                                    <Typography fontSize={"1rem"} color={"rgba(0, 0, 0, 0.6)"}>
                                      visibility
                                    </Typography>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      size="small"
                                      fullWidth
                                      value={applicationAccess || ""}
                                      onChange={handle.applicationAccess}
                                    >
                                      {["all", "own"].map((item) => (
                                        <MenuItem value={item} key={item}>
                                          {_.startCase(item)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {/* <Switch onChange={handle.applicationAccess} checked={applicationAccess || false} /> */}
                                  </Stack>
                                </FormControl>

                                <FormControl
                                  required
                                  //   error={error}
                                  sx={{ m: 3 }}
                                  variant="standard"
                                >
                                  <Stack columnGap={1} justifyContent={"center"} alignItems={"center"}>
                                    <FormLabel fontWeight={"bold"} fontSize={"0.75rem"}>
                                      Billing visibility
                                    </FormLabel>
                                    <Switch
                                      onChange={handle.userSettingsAccess}
                                      checked={userSettingsAccess || false}
                                    />
                                  </Stack>
                                </FormControl>
                              </Stack>

                              <FormControl
                                required
                                //   error={error}
                                sx={{ m: 3 }}
                                variant="standard"
                              >
                                <FormLabel fontWeight={"bold"} fontSize={"0.75rem"}>
                                  Services Access
                                </FormLabel>
                                {Object.entries(servicesAccess).map(([key, value], index) => (
                                  <FormControlLabel
                                    key={index}
                                    label={servicesName(key)}
                                    control={<Checkbox name={key} checked={value} onChange={handle.servicesAccess} />}
                                  />
                                ))}
                              </FormControl>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default RolePermission;
