import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";
// import LoadingButton from "@mui/lab/LoadingButton";
// import _ from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
// import jwtDecode from "jwt-decode";
import {
  // getUser,
  connectiveLogin,
  // loginUser,
  // passwordLessLogin,
  userSelector,
  // submitTerms,
} from "../../store/slices/userSlice";
// import regex from "../../utils/regex";
// const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export default function Login() {
  const {
    error,
    // loginWithRedirect,
    user,
    isAuthenticated,
    // handleRedirectCallback,
    getAccessTokenSilently,
    // logout,
    isLoading,
  } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const urlSearchParams = new URLSearchParams(search);
  const QueryParams = Object.fromEntries(urlSearchParams.entries());
  console.log("QueryParams", QueryParams)
  // const tokenExpired = localStorage.getItem("tokenExpired");

  // const [allValues, setAllValues] = useState({
  //   email: "",
  //   password: "",
  // });

  const { isUser } = useSelector(userSelector);
  // , passwordLessLoginSuccess, isPasswordLessLoginNewUser
  // const [emailError, setEmailError] = useState(false);
  // const [passwordError, setPasswordError] = useState(false);
  const _user = useSelector(userSelector);

  console.log("isUser", isUser);
  console.log("_user", _user);

  console.log("isAuthenticated :>> ", isAuthenticated);
  console.log("user :>> ", user);

  useEffect(async () => {
    try {
      console.log({ error, isLoading });
      console.log("useEffect isAuthenticated :>> ", isAuthenticated);
      console.log("useEffect user :>> ", user);
      console.log("> Parsing redirect");

      // actions based on isAuthenticated
      if (!isAuthenticated && isAuthenticated !== undefined) {
        getAccessTokenSilently({
          detailedResponse: true,
          authorizationParams: {
            audience: `${process.env.REACT_APP_AUTH_URL}`,
            scope:
              "create:digisign delete:digisign read:digisign update:digisign create:opportunity read:opportunity update:opportunity delete:opportunity create:contact read:contact update:contact delete:contact read:document",
          },
        })
          .then(function (token) {
            if (!isUser && token) {
              console.log({ token });

              localStorage.removeItem("tokenExpired");
              sessionStorage.token = token.access_token;
              // if (token?.illionSubscriberId) {
              //   sessionStorage.illionSubscriberId = token.illionSubscriberId;
              // }
              localStorage.token = token.access_token;
              return dispatch(connectiveLogin());

              // const decoded = jwtDecode(token.id_token);
              // console.log("decoded ===>>>>>", { decoded });
              // console.log("email===>>>>>>>", decoded?.email);
              // localStorage.removeItem("tokenExpired");
              // if (decoded?.email) {
              //   sessionStorage.token = token.access_token;
              //   localStorage.token = token.access_token;
              //   return dispatch(connectiveLogin(decoded));
              // }

              // getUserInfo({ access_token: token.access_token }).then((res) => {
              //   console.log("Res", res)
              //   const decoded = res.data
              //   console.log("decoded ===>>>>>", { decoded });
              //   console.log("email===>>>>>>>", decoded?.email);
              //   localStorage.removeItem("tokenExpired");
              //   if (decoded?.email) {
              //     sessionStorage.token = token.access_token;
              //     localStorage.token = token.access_token;
              //     return dispatch(connectiveLogin(decoded));
              //   }
              // }).catch(function (err) {
              //   console.log("error ===>>>", err);
              // })
            }
          })
          .catch(function (err) {
            console.log("error ===>>>", err);
          });
      }
    } catch (err) {
      console.log("Log in failed", err);
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   if (tokenExpired) {
  //     localStorage.removeItem("token");
  //   }
  // }, [tokenExpired]);

  useEffect(() => {
    if (isUser && _user?._id) {
      if (_user?.terms) {
        // navigate("/applications");
        const appId = QueryParams?.appId
        console.log("appId", appId)

        appId && appId !== undefined && appId !== null
          ? navigate(`/application/${appId}`)
          : navigate("/applications")

      } else {
        navigate("/terms-and-conditions");
      }
    }
    // if(isUser){
    //   navigate("/applications")
    // }
  }, [isUser]);

  // useEffect(() => {
  //   function preventBack() {
  //     window.history.forward();
  //   }
  //   preventBack();
  //   window.onunload = function () {
  //     null;
  //   };
  // }, [user]);

  // const handle = {
  //   login: async () => {
  //     if (!emailError && !passwordError) {
  //       let data = {
  //         email: allValues.email,
  //         password: allValues.password,
  //         clientId: CLIENT_ID,
  //       };
  // let res = await dispatch(loginUser(data)).unwrap();
  //       if (res) {
  //         navigate("/applications");
  //         localStorage.removeItem("tokenExpired");
  //       }
  //     }
  //   },
  //   onChangeFn: (value, name) => {
  //     if (name === "email") {
  //       if (regex.email.test(value.toLowerCase())) setEmailError(false);
  //       else setEmailError(true);
  //     } else if (name === "password") {
  //       if (regex.password.test(value)) setPasswordError(false);
  //       else setPasswordError(true);
  //     }
  //     setAllValues({
  //       ...allValues,
  //       [name]: value,
  //     });
  //   },
  // };

  return (
    <>
      <Grid
        container
        item
        height="calc(100vh - 64px)"
        width="100%"
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        marginTop="-64px"
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ padding: "100px" }}
        >
          {isLoading && <p>Loading...</p>}
          <Typography
            variant="h4"
            style={{
              letterSpacing: "-1.8px",
              fontWeight: 800,
              marginBottom: "10px",
            }}
          >
            Connective Asset Finance Lodgement Portal
          </Typography>
          {/* <Typography fontWeight="400" style={{ marginBottom: "10px" }}>
            Sign in below.
          </Typography>

          <TextField
            fullWidth
            type="email"
            name="email"
            label="Email"
            value={allValues?.email}
            onChange={(event) => console.log("event")}
            margin="dense"
            error={emailError}
            helperText={emailError ? "Invalid email." : null}
          />
          <TextField
            fullWidth
            type="password"
            name="password"
            label="Password"
            value={allValues?.password}
            margin="dense"
            error={passwordError}
            helperText={
              passwordError
                ? "password must contain an uppercase letter, a number, a special character and be 8 or more characters in length."
                : null
            }
            onChange={(event) => console.log("event")}
          />
          {user?.errorMessage && (
            <Typography color="#d32f2f">{user.errorMessage}</Typography>
          )}
          {!user ? (
            <LoadingButton
              fullWidth
              loading={""}
              loadingPosition="end"
              variant="contained"
              onClick={() => handleLogin()}
              style={{ margin: "20px 0 40px" }}
            >
              Login
            </LoadingButton>
          ) : (
            <LoadingButton
              fullWidth
              loading={""}
              loadingPosition="end"
              variant="contained"
              onClick={() => logout()}
              style={{ margin: "20px 0 40px" }}
            >
              logout
            </LoadingButton>
          )} */}
          {/* <LoadingButton
            fullWidth
            color="secondary"
            loading={""}
            loadingPosition="end"
            variant="contained"
            disabled={""}
            onClick={() => handle.login()}
            style={{ margin: "20px 0 40px" }}
          >
            Login
          </LoadingButton>
          {/* <Typography>
            Forgot your password?{" "}
            <Link to="/password/forgot">Reset password</Link>
          </Typography> */}
        </Grid>
      </Grid>
    </>
  );
}
