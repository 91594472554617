import React from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, MenuItem, Menu, Box } from "@mui/material";

// import { userSelector } from "../store/slices/userSlice";
import color from "../style/color";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block",
    // },
  },

  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
  sectionDesktop: {
    display: "none",
    // [theme.breakpoints.up("md")]: {
    //   display: "flex",
    // },
  },
  sectionMobile: {
    display: "flex",
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
}));

export default function PreAuthTopMenu() {
  // const { pathname } = useLocation();

  // const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  // const user = useSelector(userSelector);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const handleProfileMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = (event) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    ></Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" elevation={0} style={{ background: color.white, zIndex: 1000000 }}>
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
         
          </IconButton> */}
          {/* <Typography
            variant="h6"
            noWrap
            style={{ color: "black", fontWeight: 800, letterSpacing: "-0.5px" }}
          >
            Connective
          </Typography> */}
          <Box>
            <img src="/static/connective-logo.svg" alt="Connective Logo" style={{ height: "34px", width: "auto" }} />
          </Box>
          {/* <List
            component="nav"
            aria-label="main mailbox folders"
            style={{ display: "flex", flexDirection: "row", padding: 0 }}
          >
            <ListItem button style={{ padding: 0 }}>
              <ListItemText primary="Financeable" style={{ color: "black" }} />
            </ListItem>
            <ListItem button>
             
              <ListItemText primary="Customers" />
            </ListItem>
          </List> */}

          <div className={classes.grow} />
          {/* {pathname === "/login" && <div
            className={classes.sectionDesktop}
            style={{ display: "flex", alignItems: "center" }}
          > */}
          {/* <Typography style={{ color: "black", marginRight: "10px" }}>
              Don't have an account?
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </Button> */}

          {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
          {/* </div>
          )} */}

          {/* {pathname === "/register" && <div
            className={classes.sectionDesktop}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography style={{ color: "black", marginRight: "10px" }}>
              Already have an account?
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </div>
          } */}

          {/* <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              size="large"
            >
              <ExpandMore />
            </IconButton>
          </div> */}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
