import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const getGrade = (score) => {
  if (score > 745) return "A";
  if (score > 715) return "B";
  if (score > 675) return "C";
  if (score > 632.5) return "D";
  if (score > 607.5) return "E";
  if (score > 527.5) return "F";
  if (score > 435) return "G";
  if (score > 80) return "H";
  if (score > -515) return "I";
  return "J";
};

const IllionColorCodeWheel = ({ score }) => {
  const segments = [
    { color: "#2A6D36", grade: "A" },
    { color: "#408645", grade: "B" },
    { color: "#75C369", grade: "C" },
    { color: "#FFD24B", grade: "D" },
    { color: "#FFBC40", grade: "E" },
    { color: "#FF9B2C", grade: "F" },
    { color: "#FF841F", grade: "G" },
    { color: "#EF602A", grade: "H" },
    { color: "#E24234", grade: "I" },
    { color: "#AA0000", grade: "J" },
  ];

  const grade = getGrade(score);

  let secondOutlineIndex = null;

  return (
    <Box sx={{ position: "relative", display: "inline-flex", width: 150, height: 150 }}>
      <svg viewBox="0 0 36 36" style={{ transform: "rotate(-90deg)" }}>
        {segments.map((segment, index) => {
          const startAngle = (index * 360) / segments.length;
          const endAngle = ((index + 1) * 360) / segments.length;
          const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
          const x1 = 18 + 18 * Math.cos((Math.PI * startAngle) / 180);
          const y1 = 18 + 18 * Math.sin((Math.PI * startAngle) / 180);
          const x2 = 18 + 18 * Math.cos((Math.PI * endAngle) / 180);
          const y2 = 18 + 18 * Math.sin((Math.PI * endAngle) / 180);

          // Determine fill color based on score range
          let fillColor = grey[400];
          if (segment.grade <= grade) {
            fillColor = segment.color;
          }

          return (
            <g key={index}>
              {/* White outline path */}
              <path
                d={`M18 18 L ${x1} ${y1} A 18 18 0 ${largeArcFlag} 1 ${x2} ${y2} Z`}
                fill="white"
                strokeWidth="2"
                stroke={"white"}
              />
              {/* Colored segment */}
              <path d={`M18 18 L ${x1} ${y1} A 18 18 0 ${largeArcFlag} 1 ${x2} ${y2} Z`} fill={fillColor} />
            </g>
          );
        })}
      </svg>
      <Box
        sx={{
          position: "absolute",
          top: "13%",
          left: "13%",
          width: "75%",
          height: "75%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "2.5%",
            left: "2.5%",
            width: "95%",
            height: "95%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
        >
          <svg viewBox="0 0 36 36" style={{ transform: "rotate(-90deg)" }}>
            {segments.map((segment, index) => {
              const startAngle = (index * 360) / segments.length;
              const endAngle = ((index + 1) * 360) / segments.length;
              const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
              const x1 = 18 + 18 * Math.cos((Math.PI * startAngle) / 180);
              const y1 = 18 + 18 * Math.sin((Math.PI * startAngle) / 180);
              const x2 = 18 + 18 * Math.cos((Math.PI * endAngle) / 180);
              const y2 = 18 + 18 * Math.sin((Math.PI * endAngle) / 180);

              let fillColor = grey[400];

              if (segment.grade === grade) {
                fillColor = segment.color;
                secondOutlineIndex = index + 1;
              }

              return (
                <g key={index}>
                  <defs>
                    <clipPath id="gaugeClipPath">
                      <circle cx="18" cy="18" r="19" fill="white" />
                    </clipPath>
                  </defs>
                  ;
                  <path
                    d={`M 18 18 L ${x1} ${y1} L ${x2} ${y2} Z`}
                    fill="white"
                    strokeWidth="2.75"
                    stroke={segment.grade === grade || secondOutlineIndex === index ? "white" : "none"}
                    clip-path="url(#gaugeClipPath)"
                  />
                  {/* Colored path */}
                  <path d={`M18 18 L ${x1} ${y1} A 18 18 0 ${largeArcFlag} 1 ${x2} ${y2} Z`} fill={fillColor} />
                </g>
              );
            })}
          </svg>
          <Box
            sx={{
              position: "absolute",
              top: "8.5%",
              left: "8.5%",
              width: "83%",
              height: "83%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              backgroundColor: "white",
            }}
          >
            <Typography variant="h4" component="div" color="textPrimary">
              {score}
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              color="textSecondary"
              sx={{ position: "absolute", top: "60%" }}
            >
              ({grade})
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IllionColorCodeWheel;
