import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
// import _ from "lodash";
import InfoIcon from '@mui/icons-material/Info';
import * as Yup from "yup";
import { debounce } from "lodash";
import regex from "../../utils/regex";
import { frequencyForIncomeList, incomeTypesList, validFrequencyList } from "../../constants";
import { NumericFormatCustom, currencyMaskedValue } from "../../utils/currencyMaskFormat";
import {
  saveValue,
  // createFinanceItem,
  createIncome,
  // deleteFinanceItem,
  deleteIncome,
  updateIncome,
  // updateIncomeList,
  userDetailsSelector,
  setRequiredFieldsErrors,
} from "../../store/slices/applicationFormSlice";
import { customerSelector } from "../../store/slices/customerSlice";

const CustomTooltip = () => (
  <Tooltip
    title={
      <Typography variant="span">
        Primary and secondary incomes will be linked to the employer in Mercury, while all other income types will be linked to the customer.
      </Typography>
    }
    placement="right"
    arrow
  >
    <IconButton>
      <InfoIcon sx={{ color: '#1d4164', cursor: 'pointer' }} fontSize="small" />
    </IconButton>
  </Tooltip>
);

export default function Income({ income, customerId, applicationId, personalFinLoaders, customerIndex }) {
  const dispatch = useDispatch();
  const { isCustomerLogin = false } = useSelector(customerSelector);

  // const [newIncomeItemName, setNewIncomeItemName] = useState("");
  const { requireFieldErrors } = useSelector(userDetailsSelector);
  // const { consumerFieldErrors } = lenderRequiredFieldsError;
  const [incomeList, setIncomeList] = useState(income || [])

  useEffect(() => {
    if (income?.length > 0) {
      setIncomeList(income)
    }
  }, [income])

  // const [incomeFieldErrors, setIncomeFieldErrors] = useState({
  //   incomeType: "",
  //   amount: "",
  //   frequency: "",
  // });

  // useEffect(() => {
  //   if (consumerFieldErrors?.income) {
  //     setIncomeFieldErrors({
  //       incomeType: consumerFieldErrors?.income?.incomeType || "",
  //       amount: consumerFieldErrors?.income?.amount || "",
  //       frequency: consumerFieldErrors?.income?.frequency || "",
  //     })
  //   }
  // }, [consumerFieldErrors?.income])

  const [changeDetected, setChangeDetected] = useState(false);
  const isAddIncomeLoader = personalFinLoaders?.addIncomeLoader && personalFinLoaders?.id === customerId;

  const validIncomeTypesList = incomeTypesList.map(item => item.value);

  const validationSchema = Yup.object({
    incomeType: Yup.string()
      .oneOf(validIncomeTypesList, "Please select valid income type")
      .required("Income type is required"),
    amount: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
    frequency: Yup.string()
      .oneOf(validFrequencyList, "Please select valid frequency")
      .required("Frequency is required"),
  });

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        const field = fieldName.split("_")[0];
        await validationSchema.validateAt(field, { [field]: value });
        dispatch(setRequiredFieldsErrors({
          ...requireFieldErrors,
          [fieldName]: ""
        }))
      } catch (error) {
        dispatch(setRequiredFieldsErrors({
          ...requireFieldErrors,
          [fieldName]: error.message
        }))
      }
    }, 300),
    []
  );

  // const validateField = async (fieldName, value) => {
  //   try {
  //     const field = fieldName.split("_")[0];
  //     await validationSchema.validateAt(field, { [field]: value });
  //     // setIncomeFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  //     dispatch(setRequiredFieldsErrors({
  //       ...requireFieldErrors,
  //       [fieldName]: ""
  //     }))
  //   } catch (error) {
  //     // setIncomeFieldErrors((prevErrors) => ({
  //     //   ...prevErrors,
  //     //   [fieldName]: error.message,
  //     // }));
  //     dispatch(setRequiredFieldsErrors({
  //       ...requireFieldErrors,
  //       [fieldName]: ""
  //     }))
  //   }
  // };

  const onAddNewIncome = () => {
    const data = {
      incomeType: "",
      frequency: "",
      amount: "",
      application: applicationId,
      customer: customerId,
    };
    dispatch(
      saveValue({
        personalFinLoaders: {
          ...personalFinLoaders,
          id: customerId,
        },
      }),
    );
    dispatch(createIncome(data));
  };

  const removeIncome = (Element) => {
    const data = {
      incomeId: Element._id,
      application: Element.application,
      customer: Element.customer,
    };
    delete data._id;
    dispatch(
      saveValue({
        personalFinLoaders: {
          ...personalFinLoaders,
          id: Element._id,
        },
      }),
    );
    dispatch(deleteIncome(data));
  };

  const handle = {
    onBlur: (name, value, newID) => {
      const temp = [...incomeList];

      temp.map((ele) => {
        // if (!ele._id) {
        //   console.log("ele", ele);
        // }
        if (ele._id === newID) {
          let updatedAssetData = {
            ...ele,
            [name]: value,
            incomeId: newID,
            application: applicationId,
            customer: customerId,
          };
          delete updatedAssetData._id;

          if (changeDetected) {
            dispatch(updateIncome(updatedAssetData));
            setChangeDetected(false);
          }
          return updatedAssetData;
        } else {
          return ele;
        }
      });
    },
    onChange: async (field, value, newID, index) => {
      const name = field.split("_")[0];
      // if (index === 0) {
      // await validateField(field, value);
      debouncedValidation(field, value);
      // }

      const temp = [...incomeList];

      let isChangeDetected = false;

      const updatedIncomeList = temp.map((ele) => {
        if (!ele._id) {
          const dataWithOutId = {
            ...ele,
            [name]: value,
            application: applicationId,
            customer: customerId,
          };
          isChangeDetected = true;
          dispatch(createIncome(dataWithOutId));
        }
        if (ele._id === newID) {
          const updatedIncomeData = {
            ...ele,
            [name]: value,
          };
          if (ele[name] !== value) {
            isChangeDetected = true;
          }
          // validateField(name, value);
          return updatedIncomeData;
        } else {
          return ele;
        }
      });

      // Note: Update incomeType on change due to primary & secondary income disabled for customerLogin page & we can't make onBlur on that.
      if (name === "incomeType" && isChangeDetected) {
        let updatedAssetData = {
          // ...ele,
          [name]: value,
          incomeId: newID,
          application: applicationId,
          customer: customerId,
        };
        dispatch(updateIncome(updatedAssetData));
      }

      setChangeDetected(isChangeDetected);


      if (isChangeDetected) {
        setIncomeList(updatedIncomeList)
        // dispatch(updateIncomeList(updateIncome));
      }
    },
    // income: (update) => {
    //   dispatch(updateIncome(update));
    // },
    // deleteIncome: async (delData) => {
    //   await dispatch(
    //     deleteFinanceItem({
    //       data: { incomeItemId: delData._id, incomeId: income._id },
    //       endpoint: "income",
    //     }),
    //   ).unwrap();
    // },
    // createIncomeItem: async () => {
    //   try {
    //     if (incomeFieldErrors["incomeType"] === "") {
    //       await dispatch(
    //         createFinanceItem({
    //           data: {
    //             name: _.camelCase(newIncomeItemName),
    //             label: newIncomeItemName,
    //             incomeId: income._id,
    //           },
    //           endpoint: "income",
    //         }),
    //       ).unwrap();
    //       setNewIncomeItemName("");
    //     }
    //   } catch (error) {
    //     const newErrors = {};
    //     error.inner.forEach((validationError) => {
    //       newErrors[validationError.path] = validationError.message;
    //     });
    //     setIncomeFieldErrors(newErrors);
    //   }
    // },
  };

  return (
    <>
      <Grid container alignItems={"center"} justifyContent="start" mt={2} mb={1}>
        <Grid item>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "bold", color: "#1d4164" }}
          >
            Income (net)
          </Typography>
        </Grid>

        <Grid item>
          <CustomTooltip />
        </Grid>
      </Grid>
      {incomeList?.map((ele, index) => (
        <Grid container spacing={1} mb={"20px"} key={ele?._id}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  id="income-type-label"
                  error={requireFieldErrors[`incomeType_${customerId}_${index}`]}
                >
                  Income type
                </InputLabel>
                <Select
                  size="small"
                  labelId="income-type-label"
                  id={`income_type_${ele?._id}`}
                  value={ele?.incomeType ? ele.incomeType : ''} // Need to handle ternary cause not updating state in optional chaining
                  label="Income type"
                  name={`incomeType_${customerId}_${index}`}
                  onChange={(event) => {
                    handle.onChange(`incomeType_${customerId}_${index}`, event.target.value, ele?._id, index);
                  }}
                  // onBlur={() => handle.onBlur("incomeType", ele?.incomeType, ele?._id)}
                  error={requireFieldErrors[`incomeType_${customerId}_${index}`]}
                  disabled={isCustomerLogin && (ele?.incomeType === "Primary" || ele?.incomeType === "Secondary")}
                >
                  {incomeTypesList.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
                {requireFieldErrors[`incomeType_${customerId}_${index}`] && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>{requireFieldErrors[`incomeType_${customerId}_${index}`]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                id="outlined-basic"
                // type="tel"
                type="text"
                inputProps={{ min: 0 }}
                label="Income amount"
                variant="filled"
                style={{ width: "100%" }}
                value={ele?.amount}
                fullWidth
                name={`amount_${customerId}_${index}`}
                // error={`${incomeFieldErrors?.amount}${ele?._id}`}
                // helperText={`${incomeFieldErrors?.amount}${ele?._id}`}
                error={requireFieldErrors[`amount_${customerId}_${index}`]}
                helperText={requireFieldErrors[`amount_${customerId}_${index}`]}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                onChange={(event) => handle.onChange(`amount_${customerId}_${index}`, event.target.value, ele?._id, index)}
                onBlur={() => handle.onBlur("amount", currencyMaskedValue(ele?.amount), ele?._id)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  id="income-freq-label"
                  error={requireFieldErrors?.frequency}
                >
                  Income frequency
                </InputLabel>
                <Select
                  size="small"
                  labelId="income-freq-label"
                  id={`income_freq_${ele?._id}`}
                  value={ele?.frequency ? ele.frequency : ''} // Need to handle ternary cause not updating state in optional chaining
                  label="Income frequency"
                  name={`frequency_${customerId}_${index}`}
                  onChange={(event) => {
                    handle.onChange(`frequency_${customerId}_${index}`, event.target.value, ele?._id, index);
                  }}
                  onBlur={() => handle.onBlur("frequency", ele?.frequency, ele?._id)}
                  error={requireFieldErrors[`frequency_${customerId}_${index}`]}
                >
                  {frequencyForIncomeList.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
                {requireFieldErrors[`frequency_${customerId}_${index}`] && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>{requireFieldErrors[`frequency_${customerId}_${index}`]}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {!isCustomerLogin && incomeList?.length !== 0 && index !== 0 && (
              <Grid item xs={1}>
                <Button
                  onClick={() => removeIncome(ele)}
                  variant="outlined"
                  size="small"
                  // mt={"10px"}
                  style={{ marginTop: "10px" }}
                  disabled={personalFinLoaders?.removeIncomeLoader && personalFinLoaders?.id === ele?._id}
                >
                  {personalFinLoaders?.removeIncomeLoader && personalFinLoaders?.id === ele?._id
                    ? "Removing..."
                    : "Remove"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <div>
          <Button
            onClick={() => onAddNewIncome()}
            variant="outlined"
            size="small"
            disabled={
              isAddIncomeLoader ||
              (!incomeList?.[0]?.incomeType && !incomeList?.[0]?.frequency && !incomeList?.[0]?.amount ? true : false)
            }
          >
            {isAddIncomeLoader ? "Adding..." : "Add income"}
          </Button>
        </div>
      </Grid>
    </>
  );
}
