import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography, Divider, Stack } from "@mui/material";
// import * as Yup from "yup";
import { createLenderSetting, updateLenderSetting } from "../../../store/slices/userSlice";
// import regex from "src/utils/regex";
// import { fieldRequiredMessage } from "src/constants";

export default forwardRef(({ lenderSetting, lender, handleClose, addLenderDisable }, ref) => {
  const dispatch = useDispatch();
  const userData = useSelector(({ user }) => user);
  const { _id } = userData;

  const specificLenderField =
    lenderSetting?.lenderCredentials?.map((el) => el.attribute) || lender?.lenderCredentials?.map((el) => el.attribute);

  const [lenderSettingState, setLenderSettingState] = useState({
    lender: lenderSetting?.lender || lender || "",
    lenderEmail: lenderSetting?.lenderEmail || "",
    username: lenderSetting?.username || "",
    password: lenderSetting?.password || "",
    brokerId: lenderSetting?.brokerId || "",
    organisationId: lenderSetting?.organisationId || "",
    apiKey: lenderSetting?.apiKey || "",
    user: lenderSetting?.user || _id || "",
  });

  // const [fieldErrors, setFieldErrors] = useState({
  //   lenderEmail: "",
  //   username: "",
  //   password: "",
  //   brokerId: "",
  //   organisationId: "",
  //   apiKey: "",
  // });

  // const validationSchema = Yup.object({
  //   lenderEmail: Yup.string()
  //     .required("Lender email is required")
  //     .matches(regex.email, "Please enter valid email address")
  //     .min(2, "Minimum of 5 characters")
  //     .max(50, "Maximum of 50 characters"),
  //   username: Yup.string()
  //     .required("username is required")
  //     .min(3, "Minimum of 3 characters")
  //     .max(50, "Maximum of 50 characters"),
  //   password: Yup.string()
  //     .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
  //     .min(8, "Minimum of 8 characters")
  //     .max(16, "Maximum of 16 characters"),
  // brokerId: Yup.string()
  //   .required(fieldRequiredMessage.lastName)
  //   .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
  //   .min(2, "Minimum of 2 characters")
  //   .max(50, "Maximum of 50 characters"),
  // organisationId: Yup.string()
  //   .optional()
  //   .matches(regex.drivingPassportRegex, "Only alphanumeric, space and - characters allowed.")
  //   .min(6, "Minimum of 6 characters")
  //   .max(15, "Maximum of 15 characters"),
  // apiKey: Yup.string()
  //   .typeError("Please enter a valid date")
  //   .matches(regex.customerDateOfBirthRegexOptional, "Invalid date,please enter a valid date")
  //   .optional()
  //   .max(new Date(), "Date is not select in the future"),
  // });

  useEffect(() => {
    setLenderSettingState({
      ...lenderSettingState,
      lender: lender?.lender || lender,
    });
  }, [lender?.lender || lender]);

  useEffect(() => {
    if (addLenderDisable) {
      const isValid = specificLenderField?.map((field) =>
        lenderSettingState[`${field === "email" ? "lenderEmail" : field}`] !== "" ? true : false,
      );
      const isDisable = isValid?.every((field) => field === true);
      if (!isDisable) {
        addLenderDisable(true);
        return;
      } else {
        addLenderDisable(false);
        return;
      }
    }
  }, [lender?.lender || lender, lenderSettingState]);

  // const validateField = async (fieldName, value) => {
  //   try {
  //     await validationSchema.validateAt(fieldName, { [fieldName]: value });
  //     setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  //   } catch (error) {
  //     setFieldErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [fieldName]: error.message,
  //     }));
  //   }
  // };

  const handle = {
    onChange: (value, name) => {
      setLenderSettingState({
        ...lenderSettingState,
        [name]: value,
      });
    },
    onBlur: (value, name) => {
      if (lenderSetting?._id) {
        console.log("Updating");
        dispatch(
          updateLenderSetting({
            _id: lenderSetting?._id,
            ...lenderSettingState,
            [name]: value,
          }),
        );
      }
      setLenderSettingState({
        ...lenderSettingState,
        [name]: value,
      });
    },
    createNewLenderSetting: () => {
      if (!lenderSettingState.lender) {
        console.log("failed");
        return;
      }

      dispatch(createLenderSetting(lenderSettingState)).then(({ payload }) => {
        if (payload.data) {
          handleClose();
        }
      });
    },
  };

  useImperativeHandle(ref, () => ({
    handleCreateLenderSetting() {
      handle.createNewLenderSetting();
    },
  }));

  return (
    <>
      <Stack spacing={3} sx={{ width: "100%", fontSize: "10px" }}>
        {specificLenderField?.includes("email") && (
          <>
            <Stack>
              <Typography variant="h5" style={{ margin: "0 0 5px 0" }}>
                Email
              </Typography>
              <Typography variant="body2" style={{ fontSize: "13px", margin: "0 0 10px 0" }}>
                Setup the email for submission.
              </Typography>
              <Divider style={{ margin: "0 0 20px 0" }} />
              <TextField
                label="Lender email address"
                variant="filled"
                value={lenderSettingState?.lenderEmail}
                type="email"
                onChange={(event) => handle.onChange(event.target.value, "lenderEmail")}
                onBlur={(event) => handle.onBlur(event.target.value, "lenderEmail")}
                size="small"
                style={{ margin: "0 0 5px 0" }}
              />
              <Typography style={{ fontSize: "12px", margin: "0 0 3px 0" }}>
                The email address you use to submit applications to {lenderSettingState?.lender || "the lender"}.
              </Typography>
            </Stack>
            <Divider style={{ margin: "20px 0 10px 0" }} />
          </>
        )}
        {(specificLenderField?.includes("username") || specificLenderField?.includes("password")) && (
          <>
            <Stack>
              <Typography variant="h5" style={{ margin: "0 0 5px 0" }}>
                Broker Portal Credentials
              </Typography>
              <Typography variant="body2" style={{ fontSize: "13px", margin: "0 0 10px 0" }}>
                Setup your credentials for a lender's broker portal to automatically upload applications.
              </Typography>
              <Divider style={{ margin: "0 0 20px 0" }} />
              {specificLenderField?.includes("username") && (
                <TextField
                  label="Email/Username"
                  variant="filled"
                  value={lenderSettingState?.username}
                  onChange={(event) => handle.onChange(event.target.value, "username")}
                  onBlur={(event) => handle.onBlur(event.target.value, "username")}
                  size="small"
                  style={{ margin: "0 0 5px 0" }}
                />
              )}
              {specificLenderField?.includes("password") && (
                <TextField
                  label="Password"
                  variant="filled"
                  type="password"
                  value={lenderSettingState?.password}
                  onChange={(event) => handle.onChange(event.target.value, "password")}
                  onBlur={(event) => handle.onBlur(event.target.value, "password")}
                  size="small"
                  style={{ margin: "0 0 5px 0" }}
                />
              )}
              <Typography style={{ fontSize: "12px", margin: "0 0 3px 0" }}>
                Your credentials are encrypted and stored securely with bank level security.
              </Typography>
            </Stack>
            <Divider style={{ margin: "20px 0 10px 0" }} />
          </>
        )}

        {(specificLenderField?.includes("organisationId") ||
          specificLenderField?.includes("brokerId") ||
          specificLenderField?.includes("apiKey")) && (
          <>
            <Stack>
              <Typography variant="h5" style={{ margin: "0 0 5px 0" }}>
                API Credentials
              </Typography>
              <Typography variant="body2" style={{ fontSize: "13px", margin: "0 0 10px 0" }}>
                Setup your API credentials for automatic quoting, submission and more with the lender.
              </Typography>
              <Divider style={{ margin: "0 0 20px 0" }} />
              {specificLenderField?.includes("brokerId") && (
                <>
                  <TextField
                    label="Broker ID"
                    value={lenderSettingState?.brokerId}
                    onChange={(event) => handle.onChange(event.target.value, "brokerId")}
                    onBlur={(event) => handle.onBlur(event.target.value, "brokerId")}
                    variant="filled"
                    size="small"
                    style={{ margin: "0 0 3px 0" }}
                  />
                  <Typography style={{ fontSize: "12px", margin: "0 0 10px 0" }}>
                    Your ID given to you by {lenderSettingState?.lender || "the lender"}.
                  </Typography>
                </>
              )}
              {specificLenderField?.includes("organisationId") && (
                <>
                  <TextField
                    label="Partner/Merchant ID"
                    variant="filled"
                    value={lenderSettingState?.organisationId}
                    onChange={(event) => handle.onChange(event.target.value, "organisationId")}
                    onBlur={(event) => handle.onBlur(event.target.value, "organisationId")}
                    size="small"
                    style={{ margin: "0 0 3px 0" }}
                  />
                  <Typography style={{ fontSize: "12px", margin: "0 0 10px 0" }}>
                    The ID of your organisation assigned by {lenderSettingState?.lender || "the lender"}.
                  </Typography>
                </>
              )}
              {specificLenderField?.includes("apiKey") && (
                <>
                  <TextField
                    label="API Key"
                    variant="filled"
                    type="password"
                    value={lenderSettingState?.apiKey}
                    onChange={(event) => handle.onChange(event.target.value, "apiKey")}
                    onBlur={(event) => handle.onBlur(event.target.value, "apiKey")}
                    size="small"
                    style={{ margin: "0 0 3px 0" }}
                  />
                  <Typography style={{ fontSize: "12px", margin: "0 0 10px 0" }}>
                    Your unique identifier provided by {lenderSettingState?.lender || "the lender"}.
                  </Typography>
                </>
              )}
            </Stack>
            <Divider style={{ margin: "20px 0 10px 0" }} />
          </>
        )}
      </Stack>
    </>
  );
});
