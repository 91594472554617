import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch } from "../../utils/useAxios";

export const createOrganisation = createAsyncThunk("organisation/create", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/`, "POST", data, rejectWithValue);
  return response;
});

export const getOrganisation = createAsyncThunk("organisation/get", async (abn, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/${abn}`, "POST", null, rejectWithValue);
  return response;
});

export const updateAddress = createAsyncThunk("address/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/address`, "POST", data, rejectWithValue);
  return response;
});

export const updateEmail = createAsyncThunk("address/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/email`, "POST", data, rejectWithValue);
  return response;
});

export const updatePhoneNumberOrg = createAsyncThunk("address/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/phone-number`, "POST", data, rejectWithValue);
  return response;
});

export const requestToJoin = createAsyncThunk("organisation/request", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/request`, "POST", data, rejectWithValue);
  return response;
});

export const inviteUsers = createAsyncThunk("organisation/invite", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/invite`, "POST", data, rejectWithValue);
  return response;
});

export const createBankDetails = createAsyncThunk("bankDetails/create", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`bank-details/`, "POST", data, rejectWithValue);
  return response;
});

export const organisationSlice = createSlice({
  name: "organisation",
  initialState: {
    isCreating: false,
    isNew: null,
    requestSuccess: false,
  },
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
  },
  extraReducers: {
    [createOrganisation.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
      state.isCreating = false;
      state.isNew = true;
    },
    [createOrganisation.pending]: (state) => {
      state.isCreating = true;
    },
    [createOrganisation.rejected]: () => {},

    [getOrganisation.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
    },
    [getOrganisation.pending]: () => {},
    [getOrganisation.rejected]: (state) => {
      state.isError = true;
    },

    [updateAddress.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
    },
    [updateAddress.pending]: () => {},
    [updateAddress.rejected]: () => {},

    [updateEmail.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
    },
    [updateEmail.pending]: () => {},
    [updateEmail.rejected]: () => {},
    [updatePhoneNumberOrg.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
    },
    [updatePhoneNumberOrg.pending]: () => {},
    [updatePhoneNumberOrg.rejected]: () => {},

    [requestToJoin.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
      state.requestSuccess = true;
    },
    [requestToJoin.pending]: (state) => {
      state.isCreating = true;
    },
    [requestToJoin.rejected]: () => {},

    [inviteUsers.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
      state.inviteUsersSuccess = true;
    },
    [inviteUsers.pending]: () => {},
    [inviteUsers.rejected]: () => {},
  },
});

export const { saveValue } = organisationSlice.actions;

export const organisationSelector = (state) => state.organisation;
