import criteria from "../../../utils/criteria";

const { loanAmountBetween, creditScoreGreaterThan, creditScoreBetween, loanPurpose } = criteria;

const base = [
  {
    name: "Tier A",
    rate: 11.49,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 50000),
      loanPurpose(["Vehicle", "Home improvements"]),

      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreGreaterThan(951),
    ],
  },
  {
    name: "Tier B",
    rate: 11.74,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 50000),
      loanPurpose(["Vehicle", "Home improvements"]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(901, 950),
    ],
  },
  {
    name: "Tier C",
    rate: 12.24,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 50000),
      loanPurpose(["Vehicle", "Home improvements"]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(851, 900),
    ],
  },
  {
    name: "Tier D",
    rate: 12.74,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 50000),
      loanPurpose(["Vehicle", "Home improvements"]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(801, 850),
    ],
  },
  {
    name: "Tier E",
    rate: 13.74,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 50000),
      loanPurpose(["Vehicle", "Home improvements"]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(751, 800),
    ],
  },
  {
    name: "Tier F",
    rate: 15.24,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 50000),
      loanPurpose(["Vehicle", "Home improvements"]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(701, 750),
    ],
  },
  {
    name: "Tier G",
    rate: 17.24,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 50000),
      loanPurpose(["Vehicle", "Home improvements"]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(651, 700),
    ],
  },
  {
    name: "Tier H",
    rate: 19.24,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 50000),
      loanPurpose(["Vehicle", "Home improvements"]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(60),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(601, 650),
    ],
  },

  {
    name: "Tier A",
    rate: 11.49,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 30000),
      loanPurpose([
        "Debt Consolidation",
        "Medical & Dental",
        "Household furnishings",
        "Education",
        "Travel",
        "Wedding",
        "Mortgage Cost Funding",
        "Other Personal Use",
      ]),

      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreGreaterThan(951),
    ],
  },
  {
    name: "Tier B",
    rate: 11.74,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 30000),
      loanPurpose([
        "Debt Consolidation",
        "Medical & Dental",
        "Household furnishings",
        "Education",
        "Travel",
        "Wedding",
        "Mortgage Cost Funding",
        "Other Personal Use",
      ]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(901, 950),
    ],
  },
  {
    name: "Tier C",
    rate: 12.24,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 30000),
      loanPurpose([
        "Debt Consolidation",
        "Medical & Dental",
        "Household furnishings",
        "Education",
        "Travel",
        "Wedding",
        "Mortgage Cost Funding",
        "Other Personal Use",
      ]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(851, 900),
    ],
  },
  {
    name: "Tier D",
    rate: 12.74,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 30000),
      loanPurpose([
        "Debt Consolidation",
        "Medical & Dental",
        "Household furnishings",
        "Education",
        "Travel",
        "Wedding",
        "Mortgage Cost Funding",
        "Other Personal Use",
      ]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(801, 850),
    ],
  },
  {
    name: "Tier E",
    rate: 13.74,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 30000),
      loanPurpose([
        "Debt Consolidation",
        "Medical & Dental",
        "Household furnishings",
        "Education",
        "Travel",
        "Wedding",
        "Mortgage Cost Funding",
        "Other Personal Use",
      ]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(751, 800),
    ],
  },
  {
    name: "Tier F",
    rate: 15.24,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 30000),
      loanPurpose([
        "Debt Consolidation",
        "Medical & Dental",
        "Household furnishings",
        "Education",
        "Travel",
        "Wedding",
        "Mortgage Cost Funding",
        "Other Personal Use",
      ]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(701, 750),
    ],
  },
  {
    name: "Tier G",
    rate: 17.24,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 30000),
      loanPurpose([
        "Debt Consolidation",
        "Medical & Dental",
        "Household furnishings",
        "Education",
        "Travel",
        "Wedding",
        "Mortgage Cost Funding",
        "Other Personal Use",
      ]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(84),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(651, 700),
    ],
  },
  {
    name: "Tier H",
    rate: 19.24,
    productType: "Standard",
    criteria: [
      loanAmountBetween(10000, 30000),
      loanPurpose([
        "Debt Consolidation",
        "Medical & Dental",
        "Household furnishings",
        "Education",
        "Travel",
        "Wedding",
        "Mortgage Cost Funding",
        "Other Personal Use",
      ]),
      // assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      // ageOfAssetLessThan(60),
      // ageOfAssetAtEndLessThan(17),
      creditScoreBetween(601, 650),
    ],
  },
];

export default base;
