import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { saveValue, updateContactFromEmail } from "../../store/slices/applicationFormSlice";
import DataGridTable from "../dataGridTable/DataGridTable";
import PageLoader from "../Application/PageLoader";
// import { round, merge } from "lodash";
// import { sendDisclosure, updateLoanDetails } from "../../store/slices/applicationFormSlice";
// import { dollarStringOptions } from "../../constants";

const ConnectiveContactModel = ({
  handleCloseConnectiveContactDialog,
  loading,
  applicationId,
  customerId,
  connectiveContactList,
  openConnectiveContactModel
}) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState({ offset: 1 });
  const [gettingContactDataLoader, setGettingContactDataLoader] = useState(false);
  // const [isSaveDataToCustomer, setIsSaveDataToCustomer] = useState(true);
  const [selectedRow, setSelectedRow] = useState({
    id: '',
    data: null
  });

  const handleRowSelection = ({ id, data }) => {
    setSelectedRow({
      id: id,
      data: data,
    });
  };

  const handle = {
    linkContactHandler: async () => {
      setGettingContactDataLoader(true)
      await dispatch(
        updateContactFromEmail({
          applicationId,
          customerId,
          email: selectedRow.data?.email,
          connectiveContactId: selectedRow.id,
          contactData: selectedRow.data,
          isSaveDataToCustomer: true // isSaveDataToCustomer
        }),
      ).unwrap();
      setGettingContactDataLoader(false)
      dispatch(saveValue({
        openConnectiveContactModel: false
      }))
      setSelectedRow({
        id: '',
        data: null
      })

    }
  };


  const connectiveContactColumns = [
    {
      field: 'select',
      headerName: 'Select',
      width: 80,
      renderCell: (params) => (
        <Radio
          checked={params.row.uniqueId === selectedRow?.id}
        // onChange={() => handleRowSelection(params.row.uniqueId)}
        />
      ),
    },
    {
      field: "firstName",
      headerName: "First name",
      type: "string",
      width: 130,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last name",
      type: "string",
      width: 130,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      width: 220,
      editable: false,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      type: "string",
      width: 160,
      editable: false,
    }
  ];

  const getRowId = (row) => row["uniqueId"]


  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={openConnectiveContactModel}
        onClose={handleCloseConnectiveContactDialog}
        disableEscapeKeyDown={true}
      >

        <DialogTitle style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Typography>
            Contact List
          </Typography>

          {/* <Stack>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={isSaveDataToCustomer} />}
                label="Use user all data"
                onClick={(event) => setIsSaveDataToCustomer(event.target.checked)}
              />
            </FormGroup>
          </Stack> */}
        </DialogTitle>

        {loading ? (
          <Grid item style={{ margin: '10px 0 10px 0' }}>
            <PageLoader text={"Contact syncing with CRM..."} />
          </Grid>
        ) : (
          <DialogContent>
            {connectiveContactList?.length > 0 ? (
              <>
                <DialogContentText>Please select contact</DialogContentText>
                <Grid item style={{ maxHeight: 420, width: '100%' }}>
                  <DataGridTable
                    data={connectiveContactList || []}
                    columns={connectiveContactColumns}
                    onRowClick={(data) => {
                      handleRowSelection({
                        id: data?.uniqueId,
                        data: data
                      })
                    }}
                    rowsPerPageOptions={[10]}
                    pageSize={50}
                    rowCount={connectiveContactList?.length || 0} // length
                    params={params}
                    setParams={setParams}
                    page={1}
                    // isLoading={isLoading}
                    getRowId={getRowId}
                    // getRowId={true}
                    isHideTableFooter={true}
                  />
                </Grid>
              </>
            ) : (
              <Typography style={{
                margin: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '20px',
                fontWeight: 500
              }}>
                Contact linked with the CRM.
              </Typography>
            )}
          </DialogContent>
        )}

        <DialogActions style={{ padding: '0 20px 20px 0' }}>
          <Button
            disabled={loading}
            onClick={handleCloseConnectiveContactDialog}
            variant="contained"
          >
            Close
          </Button>
          {connectiveContactList?.length > 0 &&
            <Button
              disabled={!selectedRow?.id || loading || gettingContactDataLoader}
              onClick={() => handle.linkContactHandler()}
              variant="contained"
            >
              {gettingContactDataLoader ? 'Syncing...' : 'Confirm'}
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConnectiveContactModel;