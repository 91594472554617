import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import { updateUser } from "../../store/slices/userSlice";

export default function StatusCell(params) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(params.row.status);

  console.log({ params });

  // useEffect(() => {
  //   if (params.row.status === "pendingApproval") return setValue("Pending")
  //   setValue(params.row.status)
  // }, [params.row.status])

  function handleUpdateUser(value) {
    setValue(params.row.status);
    dispatch(
      updateUser({
        userId: params.row.id,
        status: value,
      }),
    );
  }

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      // label="Status"
      // placeholder="Status"
      // onClick={params.api.}
      onChange={async (event) => {
        // await dispatch(updateApplication({ _id: params.row.id, status: event.target.value })).unwrap()
        handleUpdateUser(event.target.value);
      }}
    >
      <MenuItem value="Pending">Pending</MenuItem>
      <MenuItem value="Approved">Approved</MenuItem>
      <MenuItem value="Declined">Declined</MenuItem>
    </Select>
  );
}
