import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";

import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";
import ConnectiveContactModel from "./ConnectiveContactModel";
import { customerSelector } from "../../store/slices/customerSlice";
import regex from "../../utils/regex";
import { ERRORS_MESSAGE, PLATFORM_NAME, fieldRequiredMessage } from "../../constants";
import {
  addConnectiveEmail,
  addEmail,
  saveValue,
  updateContactFromEmail,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";

export default function Email({ email, customerId, fieldErrors, setFieldErrors, singleCustomerId, applicationId }) {
  const currentPlatform = process.env.REACT_APP_PLATFORM_NAME;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { connectiveContactList, openConnectiveContactModel, gettingExistingContactListLoader, gettingExistingContactData } = useSelector(userDetailsSelector);
  const { isCustomerLogin } = useSelector(customerSelector);

  const [allValues, setAllValue] = useState({
    address: email?.address,
  });

  // const [fieldErrors, setFieldErrors] = useState({
  //   address: "",
  // });

  useEffect(() => {
    if (email?.address) {
      setAllValue({ address: email?.address });
    } else {
      setAllValue({ address: '' });
    }
  }, [email]);

  const validationSchema = Yup.object({
    address: Yup.string()
      .required(fieldRequiredMessage.address)
      .matches(regex.email, "Please enter valid email address")
      .min(2, "Minimum of 5 characters")
      .max(50, "Maximum of 50 characters"),
  });

  const debouncedValidation = useCallback(
    debounce(async (name, fieldName, value) => {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      } catch (error) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    }, 300),
    []
  );

  // const validateField = async (name, fieldName, value) => {
  //   try {
  //     await validationSchema.validateAt(name, { [name]: value });
  //     // setGuarantorFieldErrors((prevErrors) => ({
  //     //   ...prevErrors,
  //     //   emailAddress: "",
  //     // }));
  //     setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  //   } catch (error) {
  //     // setGuarantorFieldErrors((prevErrors) => ({
  //     //   ...prevErrors,
  //     //   emailAddress: error.message,
  //     // }));
  //     setFieldErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [fieldName]: error.message,
  //     }));
  //   }
  // };

  const onChangeField = (e, nameField) => {
    let isValid = true;
    const name = nameField.split("_")[0];
    isValid = e?.length <= 50;
    if (isValid) {
      setAllValue({ ...allValues, [name]: e });
    }
    // await validateField(name, nameField, e);
    debouncedValidation(name, nameField, e);
  };

  const blurFn = (fieldName, value) => {
    try {
      const isValidEmail = regex.email.test(value)

      // if (fieldErrors[fieldName] === "") {
      if (isValidEmail) {
        if (email?.address === value || value === "" || isCustomerLogin) {
          return;
        }
        // If connective platform then we need to call other api to manage this flow
        if (currentPlatform === PLATFORM_NAME.CONNECTIVE) {
          // Call get exist email api call to check wether we need to call api or not
          dispatch(
            addConnectiveEmail({
              email: value,
            }),
          ).then((res) => {
            // If data length is 0 then we need to call update connective email directly
            if (res?.payload?.data?.data?.length <= 0) {
              dispatch(
                updateContactFromEmail({
                  applicationId,
                  customerId,
                  email: value,
                  isSaveDataToCustomer: false,
                  // connectiveContactId: '',
                  // contactData: null
                }),
              );
            } else {
              // enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
              //   variant: "error",
              //   autoHideDuration: 5000,
              // });
            }
          });
        } else {
          dispatch(
            addEmail({
              emailId: email?._id,
              customerId,
              address: value,
              applicationId,
            }),
          ).then((res) => {
            if (res?.payload?.data?.data) {
              // enqueueSnackbar(SUCCESS_MESSAGE.applicationCreateSuccessMsg, {
              //   variant: "success",
              //   autoHideDuration: 5000,
              // });
            } else {
              enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
                variant: "error",
                autoHideDuration: 5000,
              });
            }
          });
        }
      }
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });
      setFieldErrors(newErrors);
    }
  };

  const handleCloseConnectiveContactDialog = (event, reason) => {
    // Make email empty if user try to cancel model
    if (reason !== "backdropClick") {
      if (connectiveContactList?.length > 0) {
        setAllValue({
          address: "",
        });
      }
      dispatch(
        saveValue({
          openConnectiveContactModel: false,
        }),
      );
    }
  };

  return (
    <React.Fragment>
      <TextField
        id="outlined-basic"
        type="text"
        label="Email"
        variant="filled"
        autoComplete="off"
        size="small"
        fullWidth
        name={`address_${customerId}`}
        value={allValues?.address || ""}
        disabled={isCustomerLogin}
        error={fieldErrors[`address_${customerId}`]} //(guarantorFieldErrors?.emailAddress) ||
        helperText={fieldErrors[`address_${customerId}`]} //(guarantorFieldErrors?.emailAddress
        onChange={(event) => onChangeField(event?.target?.value, `address_${customerId}`)}
        onBlur={(e) => blurFn(`address_${customerId}`, e?.target?.value)}
      />

      {/* model for contact details */}
      {/* {connectiveContactList?.length > 0 && ( */}
      {openConnectiveContactModel && (
        <ConnectiveContactModel
          applicationId={applicationId}
          customerId={customerId}
          loading={gettingExistingContactListLoader}
          connectiveContactList={connectiveContactList}
          openConnectiveContactModel={openConnectiveContactModel}
          gettingExistingContactData={gettingExistingContactData}
          handleCloseConnectiveContactDialog={handleCloseConnectiveContactDialog}
        />
      )}
    </React.Fragment>
  );
}
