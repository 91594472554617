import base from "./base";
import { typeOfSaleList, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const { typeOfSale, assetValueBetween, assetValueGreaterThan, assetType, employmentType, timeOfEmploymentGreaterThan } =
  criteria;

function selfEmployedCasual(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([SELF_EMPLOYED]), timeOfEmploymentGreaterThan(18)],
  }));
}

function fullTime(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME]), timeOfEmploymentGreaterThan(3)],
  }));
}

function partTimeContractor(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([CASUAL, CONTRACT]), timeOfEmploymentGreaterThan(6)],
  }));
}

const plenti = {
  date: "19 Oct 2023",
  lender: "Plenti",
  type: "personal",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fplenti-logo.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Client ID",
      attribute: "brokerId",
    },
  ],
  requiredFields: {
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      //   "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 349,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.DEALER_SALE),
        assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      ],
    },
    {
      name: "Establishment fee",
      value: 299,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), assetType(["Motorbike"])],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(2000, 9999.99)],
    },
    {
      name: "Origination fee",
      value: 1500,
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(10000, 19999.99)],
    },
    {
      name: "Origination fee",
      value: 2000,
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(20000, 34999.99)],
    },
    {
      name: "Origination fee",
      value: 2500,
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueGreaterThan(35000)],
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    `
      Temporary visa (482, 489, 491, 494, 188)
    `,
    `
      No defaults last 12 months
    `,
    `
      Paid defaults - <$1k Telco & utility
    `,
    `
      Credit repayment history
    `,
    `
      House deposit loan- 4x savings (Limit)
    `,
    `
      No Bankruptcies/Judgements
    `,
  ],
  productTiers: [...selfEmployedCasual([...base]), ...fullTime([...base]), ...partTimeContractor([...base])],
};
export default plenti;
