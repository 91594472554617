import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography, Grid, Skeleton, Card, CardActionArea, Button, Divider, Alert } from "@mui/material";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import Document from "./Document";
import CustomCardSending from "./CustomCardSending";
import { userSelector } from "../../store/slices/userSlice";
import {
  getIllionCreditReportFile,
  saveValue,
  userDetailsSelector,
  applicationSelector,
} from "../../store/slices/applicationFormSlice";
import {
  APPLICATION_TYPES,
  ERRORS_MESSAGE,
  FILE_AWAITING_MESSAGE,
  SUCCESS_MESSAGE,
  creditCardModalInfo,
} from "../../constants";
import { HtmlTooltip } from "../ProductSelector/Product";
import IllionColorCodeWheel from "../customComponents/IllionColorCodeWheel";

const IllionCreditProfile = React.memo(({ customer, applicationId }) => {
  const dispatch = useDispatch();
  const applicationState = useSelector(applicationSelector);
  const userDetails = useSelector(userSelector);
  const { enqueueSnackbar } = useSnackbar();
  const illionCreditProfile = customer?.illionCreditProfile;

  const { gettingIllionCreditFile, gettingIllionCreditProfile } = useSelector(userDetailsSelector, isEqual);

  const isIllionSubscriberId = sessionStorage.illionSubscriberId // can't be used from constant because it's undefined till page not refreshed

  const disableButton =
    (!customer?.documents?.filter((doc) => doc.name === "Privacy agreement")?.[0] &&
      !customer?.documents?.filter(
        (doc) => doc.name === "SIGNED Credit Guide and Privacy Consent - Consumer Asset Finance and Personal Loans.pdf",
      )?.[0] &&
      !customer?.documents?.filter((doc) => doc.name === "SIGNED Privacy Consent - Asset Finance.pdf")?.[0] &&
      !customer?.documents?.filter((doc) => doc.name?.includes('Privacy'))?.[0]
    ) ||
    !customer?.customerTitle ||
    !customer?.firstName ||
    !customer?.lastName ||
    !customer?.IDNumber ||
    (applicationState?.applicationType !== APPLICATION_TYPES.PERSONAL && !applicationState?.asset?.assetValue) ||
    !customer?.addresses?.[0] ||
    gettingIllionCreditProfile ||
    !isIllionSubscriberId;

  const handleGetIllionCreditFile = () => {
    dispatch(saveValue({ gettingIllionCreditProfile: true }));
    dispatch(
      getIllionCreditReportFile({
        customerId: customer._id,
        applicationId,
      }),
    ).then((res) => {
      if (res?.payload?.data && Object.keys(res?.payload?.data).length > 0) {
        enqueueSnackbar(SUCCESS_MESSAGE.creditFile, {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };
  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        {!isIllionSubscriberId &&
          <Grid container item xs={12} md={12} style={{ padding: "0 0 15px 0" }}>
            <Stack>
              <Alert severity="info">
                Kindly add the illion subscriber ID to mercury. If it has already been added, please re-log into Financeable.
              </Alert>
            </Stack>
          </Grid>
        }

        <Typography fontWeight={600} style={{ marginBottom: "15px" }}>
          Illion Credit Profile
        </Typography>
        {illionCreditProfile !== undefined &&
          (!illionCreditProfile?._id || !illionCreditProfile?.isValidConsumerIllionReport) && (
            <Typography
              variant="subtitle1"
              fontWeight={600}
              textAlign={"center"}
              sx={{ padding: "20px", marginBottom: "20px" }}
            >
              No Illion data available
            </Typography>
          )}

        {((illionCreditProfile && !illionCreditProfile?.isValidConsumerIllionReport) || !illionCreditProfile) && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {/* Illion Request Card */}
              <HtmlTooltip
                tooltipPadding={"20px"}
                title={
                  !userDetails?.is_service_enable && (
                    <React.Fragment>
                      <Typography color="inherit" marginBottom="5px">
                        {creditCardModalInfo.mainTitle}
                      </Typography>
                      <Typography color="inherit" fontSize="14px" marginBottom="10px">
                        {creditCardModalInfo.subTitle}
                      </Typography>
                      <Link to="/billing/creditCard">
                        <Button variant="outlined" size="small">
                          {creditCardModalInfo.btnText}
                        </Button>
                      </Link>
                    </React.Fragment>
                  )
                }
              >
                <Card>
                  <CardActionArea
                    disableRipple={disableButton}
                    onClick={() => {
                      if (!userDetails?.is_service_enable || disableButton || !isIllionSubscriberId) return;
                      handleGetIllionCreditFile();
                    }}
                  >
                    <CustomCardSending
                      title={"Get Credit file"}
                      disabled={!userDetails?.is_service_enable || disableButton}
                      description={` Get ${customer.firstName}'s credit report using Illion Credit Checker.`}
                      imagePath={"/static/illion-credit-check-logo.png"}
                      loadingState={false}
                      financialState={false}
                      icon={false}
                      lastTitle={"Sending SMS link..."}
                    />
                  </CardActionArea>
                </Card>

                <Typography variant="caption">
                  {!isIllionSubscriberId
                    ? `Requires Illion subscriber Id to mercury.`
                    : (disableButton &&
                      `${gettingIllionCreditFile !== undefined && !gettingIllionCreditFile ? "Requires" : ""}
                ${!customer?.firstName && !customer?.lastName ? `${applicationState?.applicationType === APPLICATION_TYPES.COMMERCIAL ? "guarantor" : "borrower"} full name, ` : ""}
                ${applicationState?.applicationType !== APPLICATION_TYPES.PERSONAL && !applicationState?.asset?.assetValue ? "Purchase price" : ""}
                ${!customer?.dateOfBirth ? `DOB, ` : ""}
                ${!customer?.customerTitle ? `Title, ` : ""}
                ${!customer?.addresses?.[0] ? `address, ` : ""}
                ${!customer?.IDNumber ? `licence number, ` : ""}
                ${!customer?.documents?.filter((doc) => doc.name === "Privacy agreement")?.[0] &&
                        !customer?.documents?.filter(
                          (doc) =>
                            doc.name ===
                            "SIGNED Credit Guide and Privacy Consent - Consumer Asset Finance and Personal Loans.pdf",
                        )?.[0] &&
                        !customer?.documents?.filter((doc) => doc.name === "SIGNED Privacy Consent - Asset Finance.pdf")?.[0]
                        ? `signed privacy statement.`
                        : ""
                      }`) ||
                    ""}
                </Typography>
              </HtmlTooltip>
            </Grid>
          </Grid>
        )}

        {gettingIllionCreditProfile && (
          <>
            <Grid item container spacing={2} style={{ marginBottom: "10px", marginTop: "10px" }}>
              <Grid item sm={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
              <Grid item sm={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item container>
                <Grid item sm={4}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item container>
                  <Grid item sm={7}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={8}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={9}>
                    <Skeleton variant="text" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item sm={4}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item container>
                  <Grid item sm={7}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={8}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={10}>
                    <Skeleton variant="text" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {illionCreditProfile && illionCreditProfile?.isValidConsumerIllionReport && (
          <>
            <Stack direction={{ xs: "column", sm: "row" }} mb={2} spacing={{ sm: 6, md: 4, lg: 2 }}>
              <Grid item sm={3} xs={12}>
                <IllionColorCodeWheel score={illionCreditProfile?.score} />
              </Grid>
              <Grid
                container
                item
                md={12}
                sm={9}
                xs={12}
                justifyContent="center"
                direction="column"
                sx={{ marginBottom: { xs: "12px", sm: 0 } }}
              >
                <Typography variant="subtitle2" fontWeight={600} sx={{ marginBottom: 1 }}>
                  {illionCreditProfile?.scoreCard}
                </Typography>
                <Typography variant="body2">
                  A score of{" "}
                  <Typography component="span" variant="body2" fontWeight="bold">
                    {illionCreditProfile?.score}
                  </Typography>{" "}
                  indicates that the applicant has a score that is better than or equal to{" "}
                  <Typography component="span" variant="body2" fontWeight="bold">
                    {illionCreditProfile?.scorePercentile}%
                  </Typography>{" "}
                  of all consumers on the illion database.
                </Typography>
              </Grid>
            </Stack>
            <Stack direction="row" spacing={3} mb={2} divider={<Divider orientation="vertical" flexItem />}>
              <Grid container sm={12} margin={"15px 0"}>
                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Age of file (months)
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.ageOfFile || "0"}</Typography>
                  </Stack>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Adverse on file
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.numberOfAdverse || "0"}</Typography>
                  </Stack>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Credit enquiries
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.numberOfEnquiries || "0"}</Typography>
                  </Stack>
                </Grid>

                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Defaults
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.numberOfDefaults || "0"}</Typography>
                  </Stack>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Accounts
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.numberOfOpenAccounts || "0"}</Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container sm={12} margin={"15px 0"}>
                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Judgements
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.numberOfJudgements || "0"}</Typography>
                  </Stack>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Bankruptcies
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.numberOfBankruptcies || "0"}</Typography>
                  </Stack>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Unique lender relationships
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.uniqueLenderRelationships || "0"}</Typography>
                  </Stack>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Age of youngest account
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.ageOfYoungestAccount || "0"}</Typography>
                  </Stack>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={600}>
                      Accounts in hardship
                    </Typography>
                    <Typography variant="body2">{illionCreditProfile?.accountsInHardship || "0"}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </>
        )}
        {customer?.documents?.filter((doc) => doc.name === "Illion Credit file" || doc.name === "No record illion credit file")
          ?.length <= 0 &&
          gettingIllionCreditFile && (
            <Stack spacing={1} direction="row" margin={"20px 0 0 0"}>
              <Typography variant="caption">{FILE_AWAITING_MESSAGE.gettingIllionCreditPDFMsg}</Typography>
            </Stack>
          )}

        <Stack spacing={1} direction="row" style={{ margin: "20px 0 20px 0" }}>
          {customer?.documents?.length > 0 &&
            customer?.documents
              ?.filter((doc) => doc.name === "Illion Credit file" || doc.name === "No record illion credit file")
              .map((doc) => <Document document={doc} customer={customer} name="creditProfile" />)}
        </Stack>
      </Grid>
    </>
  );
});

export default IllionCreditProfile;
