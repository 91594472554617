import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Typography,
  Radio,
} from "@mui/material";
import DataGridTable from "../dataGridTable/DataGridTable";
import PageLoader from "../Application/PageLoader";

const DocumentSelectionModal = ({
  openDocumentSelectionModal,
  closeDocumentSelectionModal,
  documentSelectionData,
  gettingDocumentSelections,
  privacyLinkParams,
  handleSendPrivacyLink,
}) => {
  const [params, setParams] = useState({ offset: 1 });
  const [selectedRow, setSelectedRow] = useState({ id: "", data: null });

  const { customerId, applicationId, mobile } = privacyLinkParams;

  const handleRowSelection = ({ id, data }) => {
    setSelectedRow({
      id: id,
      data: data,
    });
  };

  const handle = {
    submit: () => {
      let templateId = selectedRow.id;
      // let templateId = "";
      // if (!selectedRow.data.isConnectiveTemplate) {
      //   templateId = selectedRow.id;
      // }

      handleSendPrivacyLink(customerId, applicationId, mobile, templateId);
      setSelectedRow({
        id: "",
        data: null,
      });
      closeDocumentSelectionModal();
    },
  };

  const documentSelectionColumns = [
    {
      field: "select",
      headerName: "Select",
      width: 80,
      sortable: false,
      renderCell: (params) => <Radio checked={params.row.id === selectedRow?.id} />,
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      width: 750,
      editable: false,
      valueGetter: (value) => `${value.row.name} ${value.row.isConnectiveTemplate ? "( Master )" : ""}`,
    },
  ];

  const getRowId = (row) => row["id"];

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openDocumentSelectionModal}
        // onClose={}
        disableEscapeKeyDown={true}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>Documents Selection</Typography>
        </DialogTitle>

        {gettingDocumentSelections ? (
          <Grid item style={{ margin: "10px 0 10px 0" }}>
            <PageLoader text={"Loading Documents Selection..."} />
          </Grid>
        ) : (
          <DialogContent>
            <DialogContentText>Please select document selection</DialogContentText>
            <Grid item style={{ maxHeight: 420, width: "100%" }}>
              <DataGridTable
                data={documentSelectionData || []}
                columns={documentSelectionColumns}
                onRowClick={(data) => {
                  handleRowSelection({ id: data?.id, data });
                }}
                rowsPerPageOptions={[10]}
                pageSize={50}
                rowCount={documentSelectionData?.length || 0}
                params={params}
                setParams={setParams}
                page={1}
                getRowId={getRowId}
                isHideTableFooter={true}
              />
            </Grid>
          </DialogContent>
        )}

        <DialogActions style={{ padding: "0 20px 20px 0" }}>
          <Button variant="contained" onClick={() => closeDocumentSelectionModal()}>
            Close
          </Button>
          <Button
            disabled={!selectedRow?.id || gettingDocumentSelections}
            onClick={() => handle.submit()}
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocumentSelectionModal;
