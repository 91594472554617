import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch } from "../../utils/useAxios";

// export const customerLogin = createAsyncThunk("customer/login", async (data, { rejectWithValue }) => {
//   const response = await reusableWithoutTokenFetch(`auth/customer/login`, "POST", data, rejectWithValue);
//   return response;
// });

export const getAllCustomers = createAsyncThunk("customers/get", async (userId, { rejectWithValue }) => {
  const response = await reusableFetch(`customer/all`, "POST", rejectWithValue);
  return response;
});

export const getCustomerById = createAsyncThunk("getCustomerById/get", async ({ customerId }, { rejectWithValue }) => {
  const customer = await reusableFetch(`customer/${customerId}`, "GET", rejectWithValue);
  return customer;
});


const defaultState = {
  contactData: null,
  // isUserContact: false,
  isCustomerLogin: false,
}

export const customerSlice = createSlice({
  name: "customer",
  initialState: defaultState,
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
  },
  extraReducers: {
    // [customerLogin.fulfilled]: (state, { payload }) => {
    //   console.log("payload", payload)
    //   state.errorMessage = null;
    //   localStorage.token = payload.data.data.token;
    //   // state.isUserContact = true
    //   state.isCustomerLogin = true
    //   state.contactData = payload.data.data?.customer
      
    // },
    // [customerLogin.pending]: (state) => {
    //   state.isFetching = true;
    // },
    // [customerLogin.rejected]: (state, { payload }) => {
    //   state.isFetching = false;
    //   state.isError = true;
    //   state.errorMessage = payload.message;
    // },
    [getAllCustomers.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data.data.getAllCustomersData);
    },
    [getCustomerById.fulfilled]: (state, { payload }) => {
      console.log("payload", payload)
      state.contactData = payload.data.data
    },
  },
});

export const { saveValue } = customerSlice.actions;

export const customerSelector = (state) => state.customer;
