import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Typography } from "@mui/material";
import { userSelector } from "../store/slices/userSlice";
import { customerSelector, getAllCustomers } from "../store/slices/customerSlice";

const columns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "dateAdded",
    headerName: "Date added",
    type: "string",
    width: 150,
    editable: true,
  },
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
    // editable: true,
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
    // editable: true,
  },
  {
    field: "entityName",
    headerName: "Entity",
    width: 150,
    // editable: true,
  },
  {
    field: "address",
    headerName: "Address",
    width: 350,
    // editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
    // editable: true,
  },
  {
    field: "phoneNumber",
    headerName: "Phone number",
    width: 150,
    // editable: true,
  },
  // {
  //   field: "id",
  //   headerName: "ID",
  //   type: "string",
  //   width: 220,
  //   editable: true,
  // },
];

export default function CustomerList() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const customers = useSelector(customerSelector);

  const [rows, setRows] = useState([]);

  function handleGetAllCustomers(userId) {
    dispatch(getAllCustomers(userId));
  }

  useEffect(() => {
    if (user) handleGetAllCustomers(user);
  }, [user]);

  useEffect(() => {
    setRows(
      customers?.docs?.map((doc) => {
        // const newDoc = _.merge({}, doc);
        const date = new Date(doc.createdAt);
        return {
          id: doc._id,
          entity: doc?.entities?.map((entity) => entity?.entityName) || "N/A",
          firstName: doc.firstName,
          lastName: doc.lastName,
          address: doc.addresses?.[0]?.fullAddress,
          email: doc.emails?.[0]?.address,
          phoneNumber: doc.phoneNumbers?.[0]?.number,
          dateAdded: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`,
        };
      }),
    );
  }, [customers]);

  return (
    <Grid
      container
      item
      direction="column"
      spacing={2}
      style={{
        // marginTop: "64px",
        padding: "20px",
      }}
    >
      <Grid item>
        <Typography
          variant="h1"
          style={{ fontSize: "32px", fontWeight: 800, letterSpacing: "-1.5px", marginBottom: "0px" }}
        >
          Customers
        </Typography>
      </Grid>
      <Grid item style={{ height: "66vh" }}>
        <DataGrid
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          // onRowClick={(event) => {
          //   navigate(`/dashboard/customer/${event.id}`);
          // }}
          rows={rows || []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          // checkboxSelection
          disableSelectionOnClick
          style={{ background: "#ffffff" }}
        />
      </Grid>
    </Grid>
  );
}
