import base from "./base";
import { typeOfSaleList, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } = employmentTypes;

const { typeOfSale, assetType, employmentType } = criteria;

const money3 = {
  date: "01 Sep 2023",
  lender: "Money3",
  type: "personal",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fmoney3-logo.svg",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      //   "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle"])],
    },
    {
      name: "Origination fee",
      value: 1320,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL])],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([BENEFITS])],
    },
    {
      name: "Monthly account fee",
      value: 28.0,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    `*Discharged bankrupts solely on benefits will be only eligible for a Mini only and will be graded bronze`,
    `*Discharged bankrupts (including negative credit scores) must be in their current employment for at least 12 months to grade silver`,
    `*Current Part IX’s will be graded Bronze for all products`,
    `Loan upto 3 years for PLA $5,001 - $10,000`,
    `Loan upto 2 years for PLA $2,000 - $5,000`,
    `Defaults accepted`,
  ],
  productTiers: [...base],
};
export default money3;
