import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
// import _ from "lodash";
import * as Yup from "yup";
import { debounce } from "lodash";
import { customerSelector } from "../../store/slices/customerSlice";
import regex from "../../utils/regex";
import { assetTypesList, validCustomerAssetType } from "../../constants";
import { NumericFormatCustom, currencyMaskedValue } from "../../utils/currencyMaskFormat";
import {
  // createFinanceItem,
  // deleteFinanceItem,
  saveValue,
  createAssets,
  deleteAssets,
  // updateAssetList,
  updateAssets,
  userDetailsSelector,
  setRequiredFieldsErrors,
} from "../../store/slices/applicationFormSlice";

export default function Assets({ assets, customerId, applicationId, personalFinLoaders, customerIndex }) {
  const dispatch = useDispatch();
  const { isCustomerLogin = false } = useSelector(customerSelector);
  // const [newAssetName, setNewAssetName] = useState("");
  const [assetList, setAssetList] = useState(assets || []);

  useEffect(() => {
    if (assets?.length > 0) {
      setAssetList(assets)
    }
  }, [assets])


  const { requireFieldErrors } = useSelector(userDetailsSelector);
  // const { consumerFieldErrors } = lenderRequiredFieldsError;

  // const [consumerAssetsFieldErrors, setConsumerAssetsFieldErrors] = useState({
  //   assetType: "",
  //   value: "",
  //   description: "",
  // });

  const [changeDetected, setChangeDetected] = useState(false);
  const isAddAssetLoader = personalFinLoaders?.addAssetLoader && personalFinLoaders?.id === customerId;

  // useEffect(() => {
  //   if (requireFieldErrors) {
  //     setConsumerAssetsFieldErrors({
  //       assetType: consumerFieldErrors?.customerAssets?.assetType || "",
  //       value: consumerFieldErrors?.customerAssets?.value || "",
  //       description: consumerFieldErrors?.customerAssets?.description || "",
  //     });
  //   }
  // }, [requireFieldErrors]);

  const validationSchema = Yup.object({
    assetType: Yup.string()
      .oneOf(validCustomerAssetType, "Please select valid asset type")
      .required("Asset type is required"),
    value: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
    description: Yup.string().required("Description is required"),
  });

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        const field = fieldName.split("_")[0];
        await validationSchema.validateAt(field, { [field]: value });
        dispatch(setRequiredFieldsErrors({
          ...requireFieldErrors,
          [fieldName]: ""
        }))
      } catch (error) {
        dispatch(setRequiredFieldsErrors({
          ...requireFieldErrors,
          [fieldName]: error.message
        }))
      }
    }, 300),
    []
  );

  // const validateField = async (fieldName, value) => {
  //   try {
  //     const field = fieldName.split("_")[0];
  //     await validationSchema.validateAt(field, { [field]: value });
  //     // setConsumerAssetsFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  //     dispatch(setRequiredFieldsErrors({
  //       ...requireFieldErrors,
  //       [fieldName]: ""
  //     }))
  //   } catch (error) {
  //     // setConsumerAssetsFieldErrors((prevErrors) => ({
  //     //   ...prevErrors,
  //     //   [fieldName]: error.message,
  //     // }));
  //     dispatch(setRequiredFieldsErrors({
  //       ...requireFieldErrors,
  //       [fieldName]: error.message
  //     }))
  //   }
  // };

  // useEffect(() => {
  //   if (!assets.length) {
  //     onAddNewAssetType();
  //     return;
  //   }
  // }, []);

  const onAddNewAssetType = () => {
    const data = {
      assetType: "",
      value: "",
      description: "",
      application: applicationId,
      customer: customerId,
    };
    dispatch(
      saveValue({
        personalFinLoaders: {
          ...personalFinLoaders,
          id: customerId,
        },
      }),
    );
    dispatch(createAssets(data));
  };

  const removeAssetType = (Element) => {
    const data = {
      assetId: Element._id,
      application: Element.application,
      customer: Element.customer,
    };
    delete data._id;
    dispatch(
      saveValue({
        personalFinLoaders: {
          ...personalFinLoaders,
          id: Element._id,
        },
      }),
    );
    dispatch(deleteAssets(data));
  };

  // const handleRequireFieldError = () => {
  // }

  const handle = {
    onBlur: (name, value, newID) => {
      const temp = [...assetList];

      temp.map((ele) => {
        // if (!ele._id) {
        //   console.log("ele", ele);
        // }
        if (ele._id === newID) {
          let updatedAssetData = {
            ...ele,
            [name]: value,
            assetId: newID,
            application: applicationId,
            customer: customerId,
          };
          delete updatedAssetData._id;

          if (changeDetected) {
            dispatch(updateAssets(updatedAssetData));
            setChangeDetected(false);
          }
          return updatedAssetData;
        } else {
          return ele;
        }
      });
    },
    onChange: async (field, value, newID, index) => {
      const name = field.split("_")[0];
      // await validateField(field, value);
      debouncedValidation(field, value);

      const temp = [...assetList];
      let isChangeDetected = false;
      const updateAssetType = temp.map((ele) => {
        if (!ele._id) {
          const dataWithOutId = {
            ...ele,
            [name]: value,
            application: applicationId,
            customer: customerId,
          };
          isChangeDetected = true;
          dispatch(createAssets(dataWithOutId));
        }
        if (ele._id === newID) {
          const updatedAssetsItem = {
            ...ele,
            [name]: value,
          };

          if (ele[name] !== value) {
            isChangeDetected = true;
          }
          return updatedAssetsItem;
        } else {
          return ele;
        }
      });

      setChangeDetected(isChangeDetected);
      if (isChangeDetected) {
        setAssetList(updateAssetType)
        // dispatch(updateAssetList(updateAssetType));
      }
    },
    // assets: (update) => {
    //   dispatch(updateAssets({ ...update, assetsId: assets._id }));
    // },
    // deleteAsset: async (delData) => {
    //   await dispatch(
    //     deleteFinanceItem({
    //       data: { assetId: delData._id, assetsId: assets._id },
    //       endpoint: "customer-assets",
    //     }),
    //   ).unwrap();
    // },
    // createAsset: async () => {
    //   try {
    //     if (consumerAssetsFieldErrors["assetType"] === "") {
    //       await dispatch(
    //         createFinanceItem({
    //           data: {
    //             name: _.camelCase(newAssetName),
    //             label: newAssetName,
    //             assetsId: assets._id,
    //           },
    //           endpoint: "customer-assets",
    //         }),
    //       ).unwrap();
    //       setNewAssetName("");
    //     }
    //   } catch (error) {
    //     const newErrors = {};
    //     error.inner.forEach((validationError) => {
    //       newErrors[validationError.path] = validationError.message;
    //     });
    //     setConsumerAssetsFieldErrors(newErrors);
    //   }
    // },
  };

  return (
    <>
      <Grid xs={12}>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: "bold" }}
          style={{ marginBottom: "5px", marginTop: "20px", color: "#1d4164" }}
        >
          Assets
        </Typography>
      </Grid>
      {assetList?.map((ele, index) => (
        <Grid container xs={12} spacing={1} mb={"20px"} key={ele?._id}>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth variant="filled">
              <InputLabel
                id="years-at-label"
                error={requireFieldErrors[`assetType_${customerId}_${index}`]}
              >
                Asset Type
              </InputLabel>
              <Select
                size="small"
                labelId="years-at-label"
                id="demo-simple-select"
                value={ele?.assetType}
                label="Asset Type"
                name={`assetType_${customerId}_${index}`}
                // IconComponent={() =>
                //   ele?.assetType !== "" ? (
                //     <ClearIcon
                //       sx={{ backgroundColor: "initial" }}
                //       onClick={() => {
                //         handle.onChange("assetType", "", ele?.id);
                //       }}
                //     />
                //   ) : (
                //     <ArrowDropDownIcon sx={{ backgroundColor: "initial" }} />
                //   )
                // }
                onChange={(event) => {
                  handle.onChange(`assetType_${customerId}_${index}`, event.target.value, ele?._id, index);
                }}
                onBlur={(event) => handle.onBlur("assetType", ele?.assetType, ele?._id, index)}
                error={requireFieldErrors[`assetType_${customerId}_${index}`]}
              >
                {assetTypesList?.map((i, index) => (
                  <MenuItem key={index} value={i.value}>
                    {i.label}
                  </MenuItem>
                ))}
              </Select>
              {requireFieldErrors[`assetType_${customerId}_${index}`] && (
                <FormHelperText sx={{ color: "#d32f2f" }}>{requireFieldErrors[`assetType_${customerId}_${index}`]}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              size="small"
              id="outlined-basic"
              type="text"
              label="Value"
              variant="filled"
              fullWidth
              value={ele?.value}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              name={`value_${customerId}_${index}`}
              onChange={(event) => handle.onChange(`value_${customerId}_${index}`, event.target.value, ele?._id, index)}
              onBlur={(event) => handle.onBlur("value", currencyMaskedValue(ele?.value), ele?._id, index)}
              error={requireFieldErrors[`value_${customerId}_${index}`]}
              helperText={requireFieldErrors[`value_${customerId}_${index}`]}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              size="small"
              id="outlined-basic"
              type="text"
              label="Description"
              variant="filled"
              style={{ width: "100%" }}
              value={ele?.description}
              name={`description_${customerId}_${index}`}
              onChange={(event) => handle.onChange(`description_${customerId}_${index}`, event?.target?.value, ele?._id, index)}
              onBlur={(event) => handle.onBlur("description", ele?.description, ele?._id, index)}
              error={requireFieldErrors[`description_${customerId}_${index}`]}
              helperText={requireFieldErrors[`description_${customerId}_${index}`]}
            />
          </Grid>
          {!isCustomerLogin && assetList?.length !== 0 && index !== 0 && (
            <Grid item xs={1}>
              <Button
                size="small"
                onClick={() => removeAssetType(ele)}
                variant="outlined"
                disabled={personalFinLoaders?.removeAssetLoader && personalFinLoaders?.id === ele?._id}
                style={{ marginTop: "10px" }}
              >
                {personalFinLoaders?.removeAssetLoader && personalFinLoaders?.id === ele?._id
                  ? "Removing..."
                  : "Remove"}
              </Button>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid item xs={12}>
        <div>
          <Button
            size="small"
            onClick={() => onAddNewAssetType()}
            variant="outlined"
            disabled={
              isAddAssetLoader || (!assetList?.[0]?.assetType && !assetList?.[0]?.value && !assetList?.[0]?.description ? true : false)
            }
          >
            {isAddAssetLoader ? "Adding..." : "Add asset"}
          </Button>
        </div>
      </Grid>
    </>
  );
}
