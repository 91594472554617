import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { MenuItem, TextField } from "@mui/material";
import { handleMixpanelQuoteEvent } from "../../../utils/mixPanelTrackEvents";
import { livingArrangementsTypes } from "../../../constants";
import { customersSelector, saveCustomer, saveCustomerProfile, userDetailsSelector } from "../../../store/slices/applicationFormSlice";
import { saveValue, userSelector } from "../../../store/slices/userSlice";
import mixpanel from "../../../services/mixpanel";


const LivingArrangements = () => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const user = useSelector(userSelector)
  const customers = useSelector(customersSelector);
  const { isNewApplication } = useSelector(userDetailsSelector);
  const [livingArrangements, setLivingArrangements] = useState(customers?.[0]?.customerProfile?.livingArrangements || "");

  function handleLivingArrangements(event) {
    handleMixpanelQuoteEvent(applicationId, "living arrangements", event.target.value)
    setLivingArrangements(event.target.value)
    dispatch(saveCustomer({
      customerId: customers[0]?._id,
      customerProfile: {
        livingArrangements: event.target.value,
      }
    }))
    dispatch(
      saveCustomerProfile({
        livingArrangements: event.target.value,
        customer: customers[0]._id,
      })
    );
    if (event.target.value === "Property owner" && user.status === "new") {
      dispatch(saveValue({ walkthroughIndex: 2 }))
      mixpanel.track(`Demo: Step 3 - Order by`)
    }
  };

  useEffect(() => {
    setLivingArrangements("")
  }, [isNewApplication])

  useEffect(() => {
    if (customers?.[0]?.customerProfile?.livingArrangements) {
      setLivingArrangements(customers?.[0]?.customerProfile?.livingArrangements)
    }
  }, [customers?.[0]?.customerProfile?.livingArrangements]);

  return (
    <TextField
      fullWidth
      className="third-step"
      variant="filled"
      style={{ background: "#ffffff" }}
      select
      value={livingArrangements}
      label="Living arrangements"
      size="small"
      onChange={handleLivingArrangements}
    >
      {Object.keys(livingArrangementsTypes).map(type => <MenuItem key={nanoid()} value={livingArrangementsTypes[type]}>
        {livingArrangementsTypes[type]}
      </MenuItem>
      )}
    </TextField >
  )
}


export default LivingArrangements