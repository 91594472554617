import { CircularProgress, Stack, Typography } from "@mui/material";

export default function PageLoader({ text }) {
  return (
    <Stack direction="column" style={{ alignItems: "center" }} spacing={3}>
      <CircularProgress />
      <Typography>{text}</Typography>
    </Stack>
  );
}
