import platinum from "./platinum";
import gold from "./gold";
import silver from "./silver";
import bronze from "./bronze";
import { typeOfSaleList, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } = employmentTypes;

const {
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetAtEndLessThan,
  creditScoreGreaterThan,
  employmentType,
  creditScoreBetween,
} = criteria;

const platinumFinal = platinum.map((product) => ({
  ...product,
  criteria: [...product.criteria, ageOfAssetAtEndLessThan(13)],
}));

const goldFinal = gold.map((product) => ({
  ...product,
  criteria: [...product.criteria, ageOfAssetAtEndLessThan(13)],
}));

const silverFinal = silver.map((product) => ({
  ...product,
  criteria: [...product.criteria, ageOfAssetAtEndLessThan(13)],
}));

const bronzeFinal = bronze.map((product) => ({
  ...product,
  criteria: [...product.criteria, ageOfAssetAtEndLessThan(13)],
}));

const money3 = {
  date: "4 Sep 2023",
  lender: "Money3",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fmoney3-logo.svg",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Broker Contact",
      attribute: "username",
    },
    {
      label: "Broker Company",
      attribute: "organisationId",
    },
    {
      label: "Broker Contact ID",
      attribute: "brokerId",
    },
    {
      label: "Broker Company ID",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetValue",
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "condition",
      "glassesMake",
      "glassesModel",
      // "Make", 
      // "Model",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      // "addresses[1].fullAddress", // What if user not have previous address ?
      // "addresses[1].city",
      // "addresses[1].state",
      // "addresses[1].postCode",
      // "addresses[1].monthsAt",
      // "addresses[1].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      "income.[0].incomeType",
      "income.[0].amount",
      "income.[0].frequency",
      "expenses.[0].expensesType",
      "expenses.[0].amount",
      "expenses.[0].frequency",
      "employers.[0].entityName",
      "employers.[0].status",
      "employers.[0].timeOfEmployment",
      "employers.[0].occupation",
      "employers.[0].manager",
      "employers.[0].phoneNumber",
      "employers.[0].industry",
      "employers.[0].address.fullAddress",
      "employers.[0].address.city",
      "employers.[0].address.state",
    ],
    loanDetails: [
      // "rate"
      // "term",
      // "balloon",
      // "deposit",
      // "tradeIn",
      // "payout",
      // "rate",
      // "rateAdjustment",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle"])],
    },
    {
      name: "Private sale",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle"])],
    },
    {
      name: "Origination fee",
      value: 1320,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL])],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([BENEFITS])],
    },
    {
      name: "Monthly account fee",
      value: 28.0,
      capitalised: false,
      frequency: "monthly",
    },
    {
      name: "Risk fee",
      value: 2,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreGreaterThan(600)],
    },
    {
      name: "Risk fee",
      value: 3,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreBetween(550, 600)],
    },
    {
      name: "Risk fee",
      value: 4,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreBetween(500, 550)],
    },
    {
      name: "Risk fee",
      value: 4.5,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreBetween(100, 499)],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [creditScoreGreaterThan(549)],
    },
    {
      value: 3,
      max: 3,
      valueType: "percentage",
      criteria: [creditScoreBetween(500, 550)],
    },
    {
      value: 0,
      max: 0,
      valueType: "percentage",
      criteria: [creditScoreBetween(0, 499)],
    },
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 10000000)],
    },
  ],
  loading: [
    {
      name: "Micro product",
      value: 3,
      valueType: "percentage",
      criteria: [employmentType([BENEFITS])],
    },
  ],
  info: [
    `Payments to be made via direct debit`,
    `A benchmark monthly minimum living expense 
    (using location and income) will be compared 
    to those declared by the applicant during 
    assessment in order to calculate serviceability`,
  ],
  productTiers: [...platinumFinal, ...goldFinal, ...silverFinal, ...bronzeFinal],
};
export default money3;
