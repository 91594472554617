import base from "./base";
import criteria from "../../../utils/criteria";

const { loanAmountBetween } = criteria;

const now = {
  date: "01 Sep 2023",
  lender: "NOW",
  type: "personal",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FNOWFinance-Logo.svg",
  actions: [],
  lenderCredentials: [
    // {
    //   label: "Email",
    //   attribute: "email",
    // },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    // {
    //   label: "Merchant ID",
    //   attribute: "organisationId",
    // },
    // {
    //   label: "User ID",
    //   attribute: "brokerId",
    // },
    // {
    //   label: "Api Key",
    //   attribute: "apiKey",
    // },
  ],
  requiredFields: {
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      // "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers[0].entityName",
      "employers[0].timeOfEmployment",
      "employers[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 350,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Origination fee",
      value: 550,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(5000, 7999.99)],
    },
    {
      name: "Origination fee",
      value: 880,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(8000, 9999.99)],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(10000, 19999.99)],
    },
    {
      name: "Origination fee",
      value: 1650,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(20000, 50000)],
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    "No lender fees",
    `Full time employed - cannot be on probation`,
    `Pay day loan active orin last 6 months - If score <600 - Declined`,
    `Max lend for credit file <12 months -$15000`,
    `No discharged bankrupts, judgements or unpaid defaults`,
  ],
  productTiers: [...base],
};
export default now;
