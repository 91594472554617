import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
// import { debounce } from "lodash";

import { Typography, Button, Stack } from "@mui/material";
import StyledTextarea from "./ApplicationNotes";
import { updateNotes, applicationSelector } from "../../store/slices/applicationFormSlice";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// const rawContent = {
//   blocks: [
//     {
//       data: {},
//       depth: 0,
//       entityRanges: [],
//       inlineStyleRanges: [],
//       key: "2vm6d",
//       text: "Type your notes here...",
//       type: "normal",
//       color: "#bbbbbb",
//     },
//   ],
//   entityMap: {},
// };

const Notes = React.memo(() => {
  const dispatch = useDispatch();
  const application = useSelector(applicationSelector);

  const notes = application?.notes?.value;

  const [noteValue, setNoteValue] = React.useState("");

  // const [editorState, setEditorState] = React.useState(
  //   EditorState.createWithContent(convertFromRaw(notes || rawContent))
  // );

  // const [rawText, setRawText] = React.useState(0);

  // const onChange = (newState) => {
  //   if (JSON.stringify(editorState) !== JSON.stringify(newState)) {
  //     setRawText(newState);
  //     setEditorState(newState);
  //   }
  // };

  // const debounced = debounce(async () => {
  //   const blocks = convertToRaw(editorState.getCurrentContent());
  //   dispatch(
  //     updateNotes({
  //       value: blocks,
  //       application: application._id,
  //       _id: notes?._id,
  //     })
  //   );
  // }, 3000);

  // React.useEffect(() => {
  //   if (rawText !== 0) debounced(editorState);
  //   return () => debounced.cancel();
  // }, [rawText, editorState]);

  React.useEffect(() => {
    if (notes?.blocks) {
      setNoteValue("");
    } else {
      setNoteValue(notes || "");
    }
  }, [notes]);

  const handle = {
    onChange: (event, key) => {
      setNoteValue(event.target.value);
    },
    onBlur: (event, key) => {
      console.log("saved click")
      dispatch(
        updateNotes({
          // value: event.target.value,
          value: noteValue,
          application: application._id,
          _id: application.notes?._id,
        }),
      );
    }
    // handleSaveNote: () => {
    //   dispatch(
    //     updateNotes({
    //       value: noteValue,
    //       application: application._id,
    //       _id: application.notes?._id,
    //     }),
    //   );
    // }
  };

  return (
    <div style={{ marginTop: "20px", padding: "0 15px 20px 15px" }}>
      <Stack style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}>
        <Typography
          style={{
            marginBottom: 20,
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          Application Notes
        </Typography>
        <Button
          size="small"
          variant="contained"
          onClick={handle.onBlur}
        >
          Save
        </Button>
      </Stack>
      {/* <Editor
        toolbar={{
          options: ["fontSize", "list", "emoji", "history"],
          list: {
            indent: false,
            outdent: false,
          },
        }}
        editorState={editorState}
        onEditorStateChange={onChange}
      /> */}

      <StyledTextarea handle={handle} value={noteValue} minH={"30em"} />
    </div>
  );
});

export default Notes;
