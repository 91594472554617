import React, { useState } from "react";
import { Button, Collapse, Typography, Box, Divider } from "@mui/material";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import SendIcon from "@mui/icons-material/Send";
import SavingsIcon from "@mui/icons-material/Savings";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EmailIcon from "@mui/icons-material/Email";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import moment from "moment";
import _ from "lodash";

const iconComponents = {
  asset: <DirectionsCarIcon sx={{ fontSize: "12px" }} />,
  LoanDetails: <RequestQuoteIcon sx={{ fontSize: "16px" }} />,
  entity: <StorefrontIcon sx={{ fontSize: "16px" }} />,
  Guarantor: <PersonIcon sx={{ fontSize: "16px" }} />,
  Address: <HomeIcon sx={{ fontSize: "16px" }} />,
  Application: <SendIcon sx={{ fontSize: "16px" }} />,
  BankStatement: <SavingsIcon sx={{ fontSize: "16px" }} />,
  CreditFile: <HomeIcon sx={{ fontSize: "16px" }} />,
  Customer: <PersonIcon sx={{ fontSize: "16px" }} />,
  CustomerAsset: <EmojiTransportationIcon sx={{ fontSize: "16px" }} />,
  CustomerProfile: <EmojiTransportationIcon sx={{ fontSize: "16px" }} />,
  GeneratePrivacyDocument: <UploadFileIcon sx={{ fontSize: "16px" }} />,
  PrivacyLink: <DirectionsCarIcon sx={{ fontSize: "16px" }} />,
  Document: <UploadFileIcon sx={{ fontSize: "16px" }} />,
  Email: <EmailIcon sx={{ fontSize: "16px" }} />,
  Employer: <PersonIcon sx={{ fontSize: "16px" }} />,
  Individual: <PersonIcon sx={{ fontSize: "16px" }} />,
  Entity: <StorefrontIcon sx={{ fontSize: "16px" }} />,
  Expense: <RequestQuoteIcon sx={{ fontSize: "16px" }} />,
  IdentityVerification: <FingerprintIcon sx={{ fontSize: "16px" }} />,
  VerificationLink: <FingerprintIcon sx={{ fontSize: "16px" }} />,
  Income: <RequestQuoteIcon sx={{ fontSize: "16px" }} />,
  Liability: <CreditCardIcon sx={{ fontSize: "16px" }} />,
  Notes: <PersonIcon sx={{ fontSize: "16px" }} />,
  PhoneNumber: <ContactPhoneIcon sx={{ fontSize: "16px" }} />,
  // Add more icon mappings as needed
};

const iconsTypes = (iconName) => {
  // Check if the icon name exists in the configuration object
  const iconComponent = iconComponents[iconName];

  // If found, return the corresponding component, otherwise return a default component
  return iconComponent || <DirectionsCarIcon sx={{ fontSize: "16px" }} />;
};

const ActivityLogSidebarGroup = ({ logs = [], maxLength = 1, handleToggle, expanded }) => {
  const [count, setCount] = useState(1);

  const logDocumentData = (activityLogValue) => {
    if (!activityLogValue) return

    let formData = { ...activityLogValue?.formData };

    if (formData) {
      delete formData?.buffer;
    }

    const updateActivityLog = {
      data: activityLogValue?.data,
      ...(formData && { formData: formData })
    };
    return updateActivityLog;
  };

  return (
    <>
      {logs.slice(0, count).map((activity, index) => {
        const id = activity["_id"] + index;
        const activityType = _.startCase(_.toLower(activity?.activityType));
        const activityDescription = activity?.description;
        // const activityDescription = _.startCase(_.toLower(activity.description))

        return (
          <TimelineItem key={id}>
            <TimelineOppositeContent
              sx={{
                fontSize: "12px",
                m: "auto 0",
                p: "0 16px 0 0",
                display: "flex",
                flexDirection: "column",
              }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {/* .tz("Australia/Sydney") */}
              <span
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "right",
                }}
              >
                {moment(activity?.createdAt).format("DD-MM-YYYY")}
              </span>

              <span
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "right",
                }}
              >
                {moment(activity?.createdAt).format("hh:mm a")}
              </span>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>{activity?.activityType && iconsTypes(activity.activityType)}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <Box sx={{ pl: "12px", px: 2 }} style={{ overflow: "auto" }}>
              {/* <Typography variant="h6" component="span" style={{ fontSize: "15px" }}>
                {activityType}
              </Typography> */}
              <Box sx={{ my: "6px" }}>
                <Typography style={{ fontSize: "14px" }}>{activityDescription}</Typography>
                {expanded === id ? (
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      handleToggle(id);
                    }}
                    sx={{ pl: "0" }}
                  >
                    Hide Details
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      handleToggle(id);
                    }}
                    sx={{ pl: "0", fontSize: "11px" }}
                  >
                    Show Details
                  </Button>
                )}
              </Box>
              {/* <IconButton
                              aria-label="expand"
                              color="text.secondary"
                              onClick={() => {
                                handleToggle(index);
                              }}
                            ></IconButton> */}
              <Collapse in={expanded === activity["_id"] + index}>
                <div>
                  <p>
                    {activityType === "Document"
                      ? activity?.values && JSON.stringify(logDocumentData(activity?.values), null, 2)
                      : JSON.stringify(activity?.values, null, 2)}
                  </p>
                </div>
              </Collapse>
            </Box>
          </TimelineItem>
        );
      })}
      {maxLength > 2 ? (
        <Divider sx={{ my: "5px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {count === 1 ? (
              <Button
                sx={{ width: "50%", fontSize: "11px" }}
                size="small"
                variant="text"
                onClick={() => setCount(maxLength)}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Show {maxLength - 1} More
              </Button>
            ) : (
              <Button
                sx={{ width: "50%", fontSize: "11px" }}
                size="small"
                variant="text"
                onClick={() => setCount(1)}
                endIcon={<KeyboardArrowUpIcon />}
              >
                Hide
              </Button>
            )}
          </Box>
        </Divider>
      ) : (
        <Divider sx={{ my: "10px" }} />
      )}
    </>
  );
};

export default ActivityLogSidebarGroup;
