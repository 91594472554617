import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ConfirmModal = ({ isOpen, title, message, setIsOpen, handle }) => {
  const closeDialog = () => {
    setIsOpen(!isOpen);
  };
  const confirmation = () => {
    handle.removeCreditCard();
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog open={isOpen} onClose={closeDialog} fullWidth maxWidth={"sm"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={confirmation} variant="outlined">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConfirmModal;
