import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Slider, Typography } from "@mui/material";
import { handleMixpanelQuoteEvent } from "../../../utils/mixPanelTrackEvents";
import {
  loanDetailsSelector,
  updateLoanDetails,
  saveLoanDetails,
} from "../../../store/slices/applicationFormSlice";

const Balloon = () => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { _id, balloon } = useSelector(loanDetailsSelector);
  const [balloonValue, setBalloonValue] = useState(balloon);

  const handleBalloon = async (value) => {
    dispatch(saveLoanDetails({ balloon: value }));
    dispatch(updateLoanDetails(
      { balloon: value, _id })
    )
  };

  return <>
    <Typography variant="subtitle2">Balloon {balloonValue}%</Typography>
    <Box style={{ padding: "0 7px" }}>
      <Slider
        color="primary"
        size="small"
        marks={[
          { value: 0, label: 0 },
          { value: 10, label: 10 },
          // { value: 15, label: 15 },
          { value: 20, label: 20 },
          // { value: 25, label: 25 },
          { value: 30, label: 30 },
          // { value: 35, label: 35 },
          { value: 40, label: 40 },
        ]}
        step={5}
        min={0}
        max={40}
        value={balloonValue || balloon || 0}
        onChange={(event) => {
          setBalloonValue(event.target.value)
        }}
        onChangeCommitted={() => {
          handleMixpanelQuoteEvent(applicationId, "balloon", balloonValue)
          handleBalloon(balloonValue)
        }}
        aria-label="Default"
        valueLabelDisplay="auto"
      />
    </Box>
  </>
}

export default Balloon