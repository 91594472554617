import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { getAllUsers, userSelector } from "../../store/slices/userSlice";
import StatusCell from "../../components/User/StatusCell";

export default function UserList() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const usersState = useSelector(userSelector);
  const users = usersState?.users;
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);

  const masterColumns = [
    {
      field: "date",
      headerName: "Date",
      width: 100,
      // editable: true,
    },
    {
      field: "brokerOrganisation",
      headerName: "Broker",
      width: 250,
      // editable: true,
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      // editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      // editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      width: 130,
      // editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 380,
      // editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      // editable: true,
      // type: 'singleSelect',
      // valueOptions: ['draft', 'Wire transfer', 'Cash'],
      width: 150,
      editable: true,
      renderEditCell: (params) => <StatusCell {...params} />,
    },
    {
      field: "cursor",
      headerName: "Cursor",
      type: "string",
      width: 0,
      editable: false,
      hide: true,
    },
  ];

  // const queryOptions = React.useMemo(
  //   () => ({
  //     cursor: mapPageToNextCursor.current[page - 1],
  //     pageSize: 10,
  //   }),
  //   [page],
  // );

  // const { isLoading, data, pageInfo } = useQuery(queryOptions);

  const handlePageChange = (newPage) => {
    // We have the cursor, we can allow the page transition.
    // if (newPage === 0 || applications.nextPage) {
    handleGetAllUsers(user, newPage + 1);
    setPage(newPage);
    // }
  };

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = React.useState(users?.totalDocs || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (users?.totalDocs !== undefined ? users?.totalDocs : prevRowCountState));
  }, [users?.totalDocs, setRowCountState]);

  function handleGetAllUsers(userId, cursor) {
    dispatch(getAllUsers({ userId, cursor }));
  }

  useEffect(() => {
    console.log("mapPageToNextCursor", users?.page);
    if (user) handleGetAllUsers(user, users?.page || 1);
  }, []);

  useEffect(() => {
    console.log("changed");
    setRows(
      users?.docs?.map((doc) => {
        // console.log("setting")
        const date = new Date(doc.createdAt);
        // console.log("doc", doc);
        return {
          id: doc?._id,
          firstName: doc?.firstName,
          lastName: doc?.lastName,
          brokerOrganisation: doc?.organisation?.name,
          phoneNumber: doc?.phoneNumber?.number,
          email: doc?.email?.address,
          userId: doc._id,
          cursor: users?.page,
          status: user?.status,
          date: `${moment(date).format("D/M")} ${moment(date).format("H:mm")}`,
        };
      }),
    );
    // console.log("rows", rows);
  }, [users]);

  return (
    <Grid container item direction="column" spacing={0} style={{ padding: "20px" }}>
      <Grid item>
        <Typography
          variant="h1"
          style={{
            color: "#1d4164",
            fontSize: "24px",
            fontWeight: 800,
            letterSpacing: "-0.5px",
            marginBottom: "10px",
            paddingBottom: "10px",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          Users
        </Typography>
      </Grid>
      <Grid item>
        <DataGrid
          autoHeight
          autoPageSize
          pagination
          editMode="cell"
          // onCellClick={(params, event) => {
          //   console.log({ params })
          //   event.defaultMuiPrevented = true;
          //   if (params.field !== "status") navigate(`/ application / ${ params.id } `);
          // }}
          // onRowClick={(event) => {
          //   console.log(event);
          // }}
          rows={rows || []}
          columns={masterColumns}
          rowsPerPageOptions={[10]}
          // checkboxSelection
          disableSelectionOnClick
          style={{ background: "#ffffff", minHeight: "60vh", border: "none" }}
          // initialState={initialState}
          // density="compact"
          pageSize={10}
          rowCount={rowCountState}
          paginationMode="server"
          onPageChange={handlePageChange}
          page={page}
          // loading={isLoading}
          sx={{
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              width: "7px",
              height: "7px",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
              backgroundColor: "#999",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
              background: "#616161",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
