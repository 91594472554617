import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import PreAuthTopMenu from "../layouts/PreAuthTopMenu";

export default function Main(props) {
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== "/register" && <PreAuthTopMenu />}
      <Container
        style={{
          borderRadius: "4px",
          margin: "0 auto",
          // background: "#ffffff",
          padding: 0,
        }}
      >
        <Outlet />
      </Container>
    </>
  );
}
