import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Slider, Typography } from "@mui/material";
import moment from "moment";
import { handleMixpanelQuoteEvent } from "../../../utils/mixPanelTrackEvents";
import {
  updateAsset,
  assetSelector,
  saveAsset,
  loanDetailsSelector,
  updateLoanDetails,
  saveLoanDetails,
} from "../../../store/slices/applicationFormSlice";

const Term = () => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { ageOfAsset, _id: assetId } = useSelector(assetSelector);
  const { _id, term } = useSelector(loanDetailsSelector);
  const [termValue, setTermValue] = useState(term);

  const handleTerm = (value) => {
    handleMixpanelQuoteEvent(applicationId, "term", value)
    const termInYears = value / 12;
    const ageOfAssetMoment = moment(ageOfAsset, "YYYY");
    const endOfTermYear = moment().add(termInYears, "year");
    const ageOfAssetAtEnd = endOfTermYear.diff(ageOfAssetMoment, "years");
    dispatch(saveAsset({ ageOfAssetAtEnd }));
    dispatch(updateAsset({ _id: assetId, ageOfAssetAtEnd }));
    dispatch(saveLoanDetails({ term: value }))
    dispatch(updateLoanDetails({ _id, term: value }));
  };


  return <>
    <Typography variant="subtitle2">
      Term {termValue || term} (mths)
    </Typography>
    <Box style={{ padding: "0 7px" }}>
      <Slider
        color="primary"
        size="small"
        marks={[
          { value: 12, label: 12 },
          // { value: 24, label: 24 },
          { value: 36, label: 36 },
          // { value: 48, label: 48 },
          { value: 60, label: 60 },
          // { value: 72, label: 72 },
          { value: 84, label: 84 },
        ]}
        step={12}
        min={12}
        max={84}
        value={termValue || term || 60}
        onChange={(event) => setTermValue(event.target.value)}
        onChangeCommitted={(event) => handleTerm(termValue)}
        defaultValue={60}
        aria-label="Default"
        valueLabelDisplay="auto"
      />
    </Box>
  </>
}

export default Term 