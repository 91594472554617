import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import HandshakeTwoToneIcon from "@mui/icons-material/HandshakeTwoTone";
import {
  APPLICATION_TYPES,
  anyForeseeableChangesImpactingLoanRepaymentDetail,
  anyForeseeableChangesImpactingLoanRepaymentOption,
  balloonPaymentAtTheEndOfTheLoanDetail,
  howApplicantIntendToDoLoanRepaymentAfterChangeDetail,
  increaseInLoanAmountForFinancingCostOfInsuranceDetail,
  whatAreYouLookingForInYourLoanDetail,
  whatAreYouLookingForInYourLoanOption,
  whatIsThePurposeOfThisApplicationDetail,
  whatIsThePurposeOfThisApplicationOption,
  whatToDoWithBalloonPaymentAtTheEndOfTheLoanTermDetail,
  whatToDoWithBalloonPaymentAtTheEndOfTheLoanTermOption,
  whyDoesThisLoanTermSuitYourRequirementsDetail,
  whyDoesThisLoanTermSuitYourRequirementsOption,
} from "../../constants";
import { loanDetailsSelector, updateNccpDeclaration } from "../../store/slices/applicationFormSlice";
import StyledTextarea from "../Application/ApplicationNotes";
import RadioButtonGroup from "../Utils/RadioButtonGroup";

const NCCPDeclaration = ({
  whatIsThePurposeOfThisApplication,
  whyDoesThisLoanTermSuitYourRequirements,
  whatAreYouLookingForInYourLoan,
  anyForeseeableChangesImpactingLoanRepayment,
  howApplicantIntendToDoLoanRepaymentAfterChange,
  increaseInLoanAmountForFinancingCostOfInsurance,
  balloonPaymentAtTheEndOfTheLoan,
  whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm,
  application,
  errorField,
  customerId,
  setErrorField,
}) => {
  const dispatch = useDispatch();
  const { balloon } = useSelector(loanDetailsSelector);

  const isPersonalLoan = application?.applicationType === APPLICATION_TYPES.PERSONAL

  const [nccpDeclarationDetail, setNCCPDeclarationDetail] = useState({
    howApplicantIntendToDoLoanRepaymentAfterChange: howApplicantIntendToDoLoanRepaymentAfterChange || "",
    whatIsThePurposeOfThisApplication: whatIsThePurposeOfThisApplication || "",
    whyDoesThisLoanTermSuitYourRequirements: whyDoesThisLoanTermSuitYourRequirements || "",
    whatAreYouLookingForInYourLoan: whatAreYouLookingForInYourLoan || "",
    anyForeseeableChangesImpactingLoanRepayment: anyForeseeableChangesImpactingLoanRepayment || "",
    increaseInLoanAmountForFinancingCostOfInsurance: increaseInLoanAmountForFinancingCostOfInsurance || "",
    balloonPaymentAtTheEndOfTheLoan: balloonPaymentAtTheEndOfTheLoan || "",
    whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm: whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm || "",
  });

  const handle = {
    onChange: (event, key) => {
      setNCCPDeclarationDetail({
        ...nccpDeclarationDetail,
        howApplicantIntendToDoLoanRepaymentAfterChange: event.target.value,
      });
    },
    onBlur: (event, key) => {
      dispatch(
        updateNccpDeclaration({
          ...nccpDeclarationDetail,
          howApplicantIntendToDoLoanRepaymentAfterChange: event.target.value,
          application: application._id,
          customer: customerId,
        }),
      );
    },
  };

  const handleNCCPDeclaration = (name, value) => {
    setNCCPDeclarationDetail({
      ...nccpDeclarationDetail,
      [name]: value,
    });
    dispatch(
      updateNccpDeclaration({
        ...nccpDeclarationDetail,
        [name]: value,
        application: application._id,
        customer: customerId,
      }),
    );
  };

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={12} sm={12} md={2}>
        <Grid item xs={12} sm={12} md={2}>
          <HandshakeTwoToneIcon style={{ marginRight: "2px" }} />
          <Typography>NCCP Declaration</Typography>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={10}
        container
        spacing={4}
        style={{
          padding: "0 0 0 10px",
        }}
      >
        <Grid item md={12}>
          <Typography variant="h6">
            Please ask the following questions to the applicant(s) and provide answers.
          </Typography>
        </Grid>

        {!isPersonalLoan && (
          <Grid item md={12}>
            <span>{whatIsThePurposeOfThisApplicationDetail}</span>
            <Grid item md={12} sx={{ marginTop: "10px" }}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="years-at-label">Purpose of this application</InputLabel>
                <Select
                  size="small"
                  labelId="years-at-label"
                  id="demo-simple-select"
                  value={nccpDeclarationDetail.whatIsThePurposeOfThisApplication}
                  label="Purpose Of This Application"
                  onChange={(event) => {
                    handleNCCPDeclaration("whatIsThePurposeOfThisApplication", event.target.value);
                  }}
                >
                  {whatIsThePurposeOfThisApplicationOption.map((i) => (
                    <MenuItem value={i}>{i}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Grid item md={12}>
          <span style={{ marginBottom: "20px" }}>{whyDoesThisLoanTermSuitYourRequirementsDetail}</span>
          <Grid item md={12} sx={{ marginTop: "10px" }}>
            <FormControl fullWidth variant="filled">
              <InputLabel id="years-at-label">Loan term suit</InputLabel>
              <Select
                size="small"
                labelId="years-at-label"
                id="demo-simple-select"
                value={nccpDeclarationDetail?.whyDoesThisLoanTermSuitYourRequirements}
                label="Loan Term Suit Your Requirements"
                onChange={(event) => {
                  handleNCCPDeclaration("whyDoesThisLoanTermSuitYourRequirements", event.target.value);
                }}
              >
                {whyDoesThisLoanTermSuitYourRequirementsOption.map((i) => (
                  <MenuItem value={i}>{i}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <span style={{ marginBottom: "20px" }}>{whatAreYouLookingForInYourLoanDetail}</span>
          <Grid item md={12} sx={{ marginTop: "10px" }}>
            <FormControl fullWidth variant="filled">
              <InputLabel id="years-at-label">LoanDetail</InputLabel>
              <Select
                size="small"
                labelId="years-at-label"
                id="demo-simple-select"
                value={nccpDeclarationDetail?.whatAreYouLookingForInYourLoan}
                label="Looking For In Your LoanDetail"
                onChange={(event) => {
                  handleNCCPDeclaration("whatAreYouLookingForInYourLoan", event.target.value);
                }}
              >
                {whatAreYouLookingForInYourLoanOption.map((i) => (
                  <MenuItem value={i}>{i}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <span style={{ marginBottom: "20px" }}>{anyForeseeableChangesImpactingLoanRepaymentDetail}</span>
          <Grid item md={12} sx={{ marginTop: "10px" }}>
            <FormControl fullWidth variant="filled">
              <InputLabel id="years-at-label">Loan repayment detail</InputLabel>

              <Select
                size="small"
                labelId="years-at-label"
                id="demo-simple-select"
                value={nccpDeclarationDetail?.anyForeseeableChangesImpactingLoanRepayment}
                label="Foreseeable Changes Impacting Loan Repayment Detail"
                onChange={(event) => {
                  handleNCCPDeclaration("anyForeseeableChangesImpactingLoanRepayment", event.target.value);
                }}
              >
                {anyForeseeableChangesImpactingLoanRepaymentOption.map((i) => (
                  <MenuItem value={i}>{i}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <span style={{ marginBottom: "20px" }}>{howApplicantIntendToDoLoanRepaymentAfterChangeDetail}</span>

          <StyledTextarea
            minH={"15em"}
            value={nccpDeclarationDetail?.howApplicantIntendToDoLoanRepaymentAfterChange}
            handle={handle}
          />
        </Grid>

        <Grid item md={12}>
          <span style={{ marginBottom: "20px" }}>{increaseInLoanAmountForFinancingCostOfInsuranceDetail}</span>
          <Grid item md={5}>
            <RadioButtonGroup
              required
              value={nccpDeclarationDetail?.increaseInLoanAmountForFinancingCostOfInsurance}
              name={"increaseInLoanAmountForFinancingCostOfInsurance"}
              handleValue={(value) => {
                handleNCCPDeclaration("increaseInLoanAmountForFinancingCostOfInsurance", value);
              }}
              options={[
                { icon: "Yes", label: "Yes" },
                { icon: "No", label: "No" },
              ]}
            />
          </Grid>
        </Grid>

        {!isPersonalLoan && (balloon ?? 0) > 0 &&  (
          <Grid item md={12}>
            <span style={{ marginBottom: "20px" }}>{balloonPaymentAtTheEndOfTheLoanDetail}</span>
            <Grid item md={5}>
              <RadioButtonGroup
                required
                value={nccpDeclarationDetail?.balloonPaymentAtTheEndOfTheLoan}
                name={"balloonPaymentAtTheEndOfTheLoan"}
                handleValue={(value) => {
                  handleNCCPDeclaration("balloonPaymentAtTheEndOfTheLoan", value);
                }}
                options={[
                  { icon: "Yes", label: "Yes" },
                  { icon: "No", label: "No" },
                ]}
              />
            </Grid>
          </Grid>
        )}

        {!isPersonalLoan && (balloon ?? 0) > 0 && (
          <Grid item md={12}>
            <span style={{ marginBottom: "20px" }}>{whatToDoWithBalloonPaymentAtTheEndOfTheLoanTermDetail}</span>
            <Grid item md={12} sx={{ marginTop: "10px" }}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="years-at-label">Balloon payment</InputLabel>
                <Select
                  size="small"
                  labelId="years-at-label"
                  id="demo-simple-select"
                  value={nccpDeclarationDetail?.whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm}
                  label="what To Do With Balloon Payment At The End Of The Loan Term"
                  onChange={(event) => {
                    handleNCCPDeclaration("whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm", event.target.value);
                  }}
                >
                  {whatToDoWithBalloonPaymentAtTheEndOfTheLoanTermOption.map((i) => (
                    <MenuItem value={i}>{i}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default NCCPDeclaration;
